<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">{{ profil.nom }} {{ profil.prenom }} - {{ profil.cp }}</h5>
            <div class="card-body">
              <h5 class="card-title mb-3">Changer de mot de passe :</h5>

              <form @submit.prevent="changeMdp()">
                <div class="form-row">
                  <div class="col-md-4 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="motdepasse">Nouveau mot de passe</label>
                    <div class="form-control-container">
                      <input type="password" class="form-control" id="motdepasse" placeholder="*******" autocomplete="off" v-model="mdp" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="confmdp">Confirmation mot de passe</label>
                    <div class="form-control-container">
                      <input type="password" class="form-control" id="confmdp" placeholder="*******" autocomplete="off" v-model="confMdp" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="form-error mt-3" v-if="status == 'error_mdp'">
                    <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                    <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                      <li>{{ err.message }}</li>
                    </ul>
                  </div>
                </div>
                <button class="btn btn-primary mt-3 float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>VALIDER</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Compte",
  data() {
    return {
      mdp: "",
      confMdp: "",
    };
  },
  mounted() {
    this.$store.commit("title", {
      desktop: "Mon Compte",
      mobile: "Mon compte",
    });
    this.$store.dispatch("getProfil");
  },
  computed: {
    validatedFields() {
      return this.mdp != "" && this.confMdp != "" ? true : false;
    },
    ...mapState(["status", "errMessage", "profil", "user"]),
  },
  methods: {
    changeMdp() {
      const self = this;
      this.$store
        .dispatch("changeMdp", {
          mdp: this.mdp,
          confMdp: this.confMdp,
          userId: this.user.userId,
        })
        .then(function () {
          self.mdp = "";
          self.confMdp = "";
          self.showAlert({
            icon: "success",
            title: "Mot de passe modifié avec succès.",
          });
        });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
      });
    },
  },
};
</script>

<style></style>
