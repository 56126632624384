<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="addJs()">
                <div class="form-row">
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label for="datedep" class="font-weight-medium mb-2 required">Date départ :</label>
                    <v-date-picker locale="fr" v-model="datedep" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="col-md-3 mb-3">
                    <label for="heuredep" class="font-weight-medium mb-2 required">Heure de départ</label>
                    <select id="heuredep" v-model="heuredep" class="form-control">
                      <option v-for="(heure, index) in choixHeure" :key="index" :value="heure" :data="heure">{{ heure }}</option>
                    </select>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label for="dateret" class="font-weight-medium mb-2 required">Date retour :</label>
                    <v-date-picker locale="fr" v-model="dateret" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="heureret">Heure retour :</label>
                    <select id="heureret" v-model="heureret" class="form-control">
                      <option v-for="(heure, index) in choixHeure" :key="index" :value="heure" :data="heure">{{ heure }}</option>
                    </select>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="kmdep">Km départ :</label>
                    <div class="form-control-container">
                      <input type="tel" class="form-control" id="kmdep" placeholder="" autocomplete="off" v-model="kmdep" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="kmret">Km retour :</label>
                    <div class="form-control-container">
                      <input type="tel" class="form-control" id="kmret" placeholder="" autocomplete="off" v-model="kmret" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="motif">Motif du déplacement :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="motif" placeholder="" autocomplete="off" v-model="motif" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <label for="iti" class="rfont-weight-medium mb-2 required">Itinéraire détaillé (départ, arrivée) : </label>
                    <div class="form-control-container">
                      <textarea class="form-control stretchy" id="iti" placeholder="" v-model="iti"></textarea>
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                </div>

                <div class="form-error mt-3" v-if="status == 'error_newjs'">
                  <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                  <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                    <li>{{ err.message }}</li>
                  </ul>
                </div>
                <div class="row justify-content-end mt-3">
                  <div class="col-md-3 col-sm-6 mb-3">
                    <button type="button" class="btn btn-block btn-secondary" @click="retour()">RETOUR</button>
                  </div>
                  <div class="col-md-3 col-sm-6 mb-3">
                    <button class="btn btn-primary btn-block float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                      <span v-if="status == 'loading'">Connexion en cours</span>
                      <span v-else>VALIDER</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NewJs",
  props: ["id", "newJsMois", "newJsAnnee"],
  data() {
    return {
      // mois: "",
      // annee: "",
      valuedatedep: null,
      valueheuredep: null,
      valuedateret: null,
      valueheureret: null,
      valuekmdep: null,
      valuekmret: null,
      valueiti: null,
      valuemotif: null,
      vehiculeId: "",
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
      choixHeure: [
        "00h00",
        "00h15",
        "00h30",
        "00h45",
        "01h00",
        "01h15",
        "01h30",
        "01h45",
        "02h00",
        "02h15",
        "02h30",
        "02h45",
        "02h45",
        "03h00",
        "03h15",
        "03h30",
        "03h45",
        "04h00",
        "04h15",
        "04h30",
        "04h45",
        "05h00",
        "05h15",
        "05h30",
        "05h45",
        "06h00",
        "06h15",
        "06h30",
        "06h45",
        "07h00",
        "07h15",
        "07h30",
        "07h45",
        "08h00",
        "08h15",
        "08h30",
        "08h45",
        "09h00",
        "09h15",
        "09h30",
        "09h45",
        "10h00",
        "10h15",
        "10h30",
        "10h45",
        "11h00",
        "11h15",
        "11h30",
        "11h45",
        "12h00",
        "12h15",
        "12h30",
        "12h45",
        "13h00",
        "13h15",
        "13h30",
        "13h45",
        "14h00",
        "14h15",
        "14h30",
        "14h45",
        "15h00",
        "15h15",
        "15h30",
        "15h45",
        "16h00",
        "16h15",
        "16h30",
        "16h45",
        "17h00",
        "17h15",
        "17h30",
        "17h45",
        "18h00",
        "18h15",
        "18h30",
        "18h45",
        "19h00",
        "19h15",
        "19h30",
        "19h45",
        "20h00",
        "20h15",
        "20h30",
        "20h45",
        "21h00",
        "21h15",
        "21h30",
        "21h45",
        "22h00",
        "22h15",
        "22h30",
        "22h45",
        "23h00",
        "23h15",
        "23h30",
        "23h45",
      ],
    };
  },

  beforeMount() {
    const self = this;
    this.$store.dispatch("getOneVehicules", this.$route.params.id).then(function () {
      self.$store.commit("title", {
        desktop: "Véhicule : " + self.vehicule.registration,
        mobile: self.vehicule.registration,
      });
    });
    this.$store.dispatch("getLastLogs", this.$route.params.id);
    this.$store.dispatch("getProfil");
    // this.mois = this.$route.query.newJsMois;
    // this.annee = this.$route.query.newJsAnnee;
  },

  computed: {
    validatedFields() {
      return this.datedep && this.heuredep && this.dateret && this.heureret && this.kmdep && this.kmret && this.iti && this.motif ? true : false;
    },
    ...mapState(["status", "errMessage", "user", "vehicule", "profil", "lastLogs"]),
    datefr() {
      const date = new Date(Date.now());
      return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
    },
    datedep: {
      get() {
        return this.valuedatedep === null ? this.datefr : this.valuedatedep;
      },
      set(value) {
        this.valuedatedep = value;
      },
    },
    mois() {
      var date = this.datedep.split("/");
      return parseInt(date[1]);
    },
    annee() {
      var date = this.datedep.split("/");
      return date[2];
    },
    heuredep: {
      get() {
        return this.valueheuredep === null ? this.lastLogs.startTime : this.valueheuredep;
      },
      set(value) {
        this.valueheuredep = value;
      },
    },
    dateret: {
      get() {
        return this.valuedateret === null ? this.datedep : this.valuedateret;
      },
      set(value) {
        this.valuedateret = value;
      },
    },
    heureret: {
      get() {
        return this.valueheureret === null ? this.lastLogs.endTime : this.valueheureret;
      },
      set(value) {
        this.valueheureret = value;
      },
    },
    kmdep: {
      get() {
        return this.valuekmdep === null ? this.lastLogs.endKm : this.valuekmdep;
      },
      set(value) {
        this.valuekmdep = value;
      },
    },
    kmret: {
      get() {
        return this.valuekmret === null ? "" : this.valuekmret;
      },
      set(value) {
        this.valuekmret = value;
      },
    },
    iti: {
      get() {
        return this.valueiti === null ? this.lastLogs.itinerary : this.valueiti;
      },
      set(value) {
        this.valueiti = value;
      },
    },
    motif: {
      get() {
        return this.valuemotif === null ? this.lastLogs.reason : this.valuemotif;
      },
      set(value) {
        this.valuemotif = value;
      },
    },
    valueMois() {
      return this.newJsMois != null ? this.newJsMois : this.mois;
    },
    valueAnnee() {
      return this.newJsAnnee != null ? this.newJsAnnee : this.annee;
    },
  },
  methods: {
    addJs() {
      if (this.valuedatedep === null) {
        this.valuedatedep = this.datefr;
      }
      if (this.valuedateret === null) {
        this.valuedateret = this.datedep;
      }
      if (this.valueheuredep === null) {
        this.valueheuredep = this.heuredep;
      }
      if (this.valueheureret === null) {
        this.valueheureret = this.heureret;
      }
      if (this.valuekmdep === null) {
        this.valuekmdep = this.lastLogs.endKm;
      }
      if (this.valueiti === null) {
        this.valueiti = this.lastLogs.itinerary;
      }
      if (this.valuemotif === null) {
        this.valuemotif = this.lastLogs.reason;
      }
      const self = this;
      this.$store
        .dispatch("addLogs", {
          startDate: this.valuedatedep.split("/").reverse().join("-"),
          endDate: this.valuedateret.split("/").reverse().join("-"),
          endTime: this.valueheureret.split("h").join(":"),
          startTime: this.valueheuredep.split("h").join(":"),
          startKm: this.valuekmdep,
          endKm: this.valuekmret,
          itinerary: this.valueiti,
          reason: this.valuemotif,
          cp: this.profil.cp,
          id_vehicle: this.id,
          userId: this.user.userId,
        })
        .then(function () {
          jQuery("#myModal").modal("hide");
          self.showAlert({
            icon: "success",
            title: "Vous avez ajouté un trajet avec succès.",
          });
          // if (self.newJsMois && self.newJsAnnee) {
          //   self.$router.push({ name: "Journal", params: { vehiculeId: self.id, retourMois: self.newJsMois, retourAnnee: self.newJsAnnee } });
          // } else {
          //   self.$router.push({ name: "Journal", params: { vehiculeId: self.id, retourMois: new Date().getMonth() + 1, retourAnnee: new Date().getFullYear() } });
          // }
          self.$router.push({ name: "Journal", params: { vehiculeId: self.id, unitId: self.vehicule.id_unit, retourMois: self.valueMois, retourAnnee: self.valueAnnee } });
        });
    },
    retour() {
      // if (this.newJsMois && this.newJsAnnee) {
      this.$router.push({ name: "Journal", params: { vehiculeId: this.vehicule.idVehicle, unitId: this.vehicule.id_unit, retourMois: this.valueMois, retourAnnee: this.valueAnnee } });
      // } else {
      //   this.$router.push({ name: "Journal", params: { vehiculeId: this.vehicule.idVehicle, unitId: this.vehicule.id_unit, retourMois: new Date().getMonth() + 1, retourAnnee: new Date().getFullYear() } });
      // }
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
        //footer: param.footer,
      });
    },
  },
};
</script>

<style></style>
