<template>
  <section class="print">
    <div class="container-fluid pt-3">
      <div class="d-none d-print-block">
        <div class="row bg-gray d-flex align-items-center py-4">
          <div class="col-3"></div>
          <div class="col-6">
            <h1 class="titre_print text-uppercase text-white text-center d-none d-md-block mb-0 mt-1">
              JOURNAUX DE SERVICE <br />
              {{ profil.site }} <br />
              {{ lettreMois }} {{ annee }}
            </h1>
          </div>
          <div class="col-3">
            <img src="../../assets/img/sncf-logo.png" class="float-right my-2 mx-4" alt="Logo SNCF" width="130" />
          </div>
        </div>
      </div>

      <div class="row d-print-none">
        <div class="col-md-12">
          <div class="card">
            <h1 class="card-header bg-white text-primary text-uppercase mt-2">{{ profil.region }} - {{ profil.site }}</h1>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-4 mb-1">
                  <label for="mois" class="font-weight-medium mb-2">Mois</label>
                  <select id="mois" v-model="mois" class="form-control" @change="changeSelect()">
                    <option value="1">Janvier</option>
                    <option value="2">Février</option>
                    <option value="3">Mars</option>
                    <option value="4">Avril</option>
                    <option value="5">Mai</option>
                    <option value="6">Juin</option>
                    <option value="7">Juillet</option>
                    <option value="8">Aout</option>
                    <option value="9">Septembre</option>
                    <option value="10">Octobre</option>
                    <option value="11">Novembre</option>
                    <option value="12">Décembre</option>
                  </select>
                </div>
                <div class="col-md-4 mb-1">
                  <label for="annee" class="font-weight-medium mb-2">Année</label>
                  <select id="annee" v-model="annee" class="form-control" @change="changeSelect()">
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Parc Auto</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalCar }}</h1>
                <footer class="blockquote-footer">Nb de véhicules </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Distance</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalKm }} Km</h1>
                <footer class="blockquote-footer">Kilomètre total </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Carburant</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalLitre }} Litres</h1>
                <footer class="blockquote-footer">Nb de litres </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Péage</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalPeage }} €</h1>
                <footer class="blockquote-footer">Cout péage </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Lavage</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalLavage }} €</h1>
                <footer class="blockquote-footer">Cout lavage </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Autre</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalAutre }} €</h1>
                <footer class="blockquote-footer">Cout autre </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Validation établissement</h5>
            <div class="card-body">
              <div class="mt-5">
                <VueApexCharts type="radialBar" height="250" :options="chartOptions" :series="pourcentage"></VueApexCharts>
                <p class="text-gray font-weight-bold text-center">Total : {{ result }} sur {{ totalCar }} véhicules</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-9 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Validation par unité</h5>
            <div class="card-body">
              <VueApexCharts type="bar" height="350" :options="chartValidation" :series="seriesValidation"></VueApexCharts>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-7 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Distance parcouru par unité</h5>
            <div class="card-body">
              <VueApexCharts type="bar" height="350" :options="chartDistance" :series="seriesDistance"></VueApexCharts>
            </div>
          </div>
        </div>
        <div class="col-sm-5 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Carburant consommé par unité</h5>
            <div class="card-body">
              <VueApexCharts type="radar" height="350" :options="chartConso" :series="seriesConso"></VueApexCharts>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Dépenses par unité</h5>
            <div class="card-body">
              <VueApexCharts type="bar" height="350" :options="chartDepense" :series="seriesDepense"></VueApexCharts>
            </div>
          </div>
        </div>
      </div>

      <div class="row d-print-none">
        <div class="col-md-12 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Liste des véhicules</h5>
            <div class="card-body">
              <TableEtablissement :dashboard="dashboard" :mois="mois" :annee="annee" :lettreMois="lettreMois" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import VueApexCharts from "vue3-apexcharts";
import TableEtablissement from "@/components/Tables/Table_Etablissement.vue";

export default {
  name: "Etablissement",
  components: { VueApexCharts, TableEtablissement },
  data() {
    return {
      valueMois: null,
      valueAnnee: null,
      cent: [100],
      result: 0,
    };
  },
  mounted() {
    this.$store.dispatch("getProfil");
    this.$store.dispatch("getDashboardEtablissement", { userId: this.user.userId, month: this.mois, year: this.annee, site: this.user.site });
  },
  updated() {
    this.$store.commit("title", {
      desktop: this.profil.site + " - " + this.lettreMois + " " + this.annee,
      mobile: this.profil.site,
    });
  },
  computed: {
    ...mapState(["dashboard", "user", "profil"]),
    mois: {
      get() {
        return this.valueMois === null ? new Date().getMonth() + 1 : this.valueMois;
      },
      set(value) {
        this.valueMois = value;
      },
    },
    annee: {
      get() {
        return this.valueAnnee === null ? new Date().getFullYear() : this.valueAnnee;
      },
      set(value) {
        this.valueAnnee = value;
      },
    },
    lettreMois() {
      if (this.mois == "1") {
        return "Janvier";
      }
      if (this.mois == "2") {
        return "Février";
      }
      if (this.mois == "3") {
        return "Mars";
      }
      if (this.mois == "4") {
        return "Avril";
      }
      if (this.mois == "5") {
        return "Mai";
      }
      if (this.mois == "6") {
        return "Juin";
      }
      if (this.mois == "7") {
        return "Juillet";
      }
      if (this.mois == "8") {
        return "Aout";
      }
      if (this.mois == "9") {
        return "Septembre";
      }
      if (this.mois == "10") {
        return "Octobre";
      }
      if (this.mois == "11") {
        return "Novembre";
      }
      if (this.mois == "12") {
        return "Décembre";
      }
    },
    totalCar() {
      return this.dashboard.length;
    },
    pourcentage() {
      this.result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        if (this.dashboard[i].validation > 0) {
          this.result += 1;
        }
      }

      const res = (this.result * 100) / this.totalCar;
      const pourcent = [Math.round(res * 100) / 100];
      return pourcent;
    },
    totalKm() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].totalMois;
      }
      return result;
    },
    totalLitre() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].litres;
      }
      return Math.round(result * 100) / 100;
    },
    totalPeage() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].peage;
      }
      return Math.round(result * 100) / 100;
    },
    totalLavage() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].lavage;
      }
      return result;
    },
    totalAutre() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].autre;
      }
      return Math.round(result * 100) / 100;
    },
    listeIdUp() {
      let result = [];
      for (let i = 0; i < this.dashboard.length; i++) {
        if (result.includes(this.dashboard[i].idUnit)) {
        } else {
          result.push(this.dashboard[i].idUnit);
        }
      }
      return result;
    },
    listeUp() {
      let result = [];
      let name = "";
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            name = this.dashboard[j].nameUnit;
          }
        }
        result.push(name);
      }
      return result;
    },
    listePourcentage() {
      let result = [];
      let count = 0;
      let total = 0;
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            if (this.dashboard[j].validation > 0) {
              count += 1;
            }
            total += 1;
          }
        }
        const res = Math.round(((count * 100) / total) * 100) / 100;
        result.push(res);
        count = 0;
        total = 0;
      }
      return result;
    },
    listePeage() {
      let result = [];
      let peage = 0;
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            peage += this.dashboard[j].peage;
          }
        }

        result.push(Math.round(peage * 100) / 100);
        peage = 0;
      }

      return result;
    },
    listeLavage() {
      let result = [];
      let lavage = 0;
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            lavage += this.dashboard[j].lavage;
          }
        }
        result.push(Math.round(lavage * 100) / 100);
        lavage = 0;
      }
      return result;
    },
    listeAutre() {
      let result = [];
      let autre = 0;
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            autre += this.dashboard[j].autre;
          }
        }
        result.push(Math.round(autre * 100) / 100);
        autre = 0;
      }
      return result;
    },
    listeKmParcourus() {
      let result = [];
      let km = 0;
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            km += this.dashboard[j].totalMois;
          }
        }
        result.push(km);
        km = 0;
      }
      return result;
    },
    listeDepenses() {
      let result = [];
      let dep = 0;
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            dep += this.dashboard[j].litres * this.prixEssence + this.dashboard[j].euros;
          }
        }
        result.push(dep);
        dep = 0;
      }
      return result;
    },
    listeCarburant() {
      let result = [];
      let carburant = 0;
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            carburant += this.dashboard[j].litres;
          }
        }
        result.push(Math.round(carburant * 100) / 100);
        // result.push(carburant);
        carburant = 0;
      }
      return result;
    },

    chartOptions() {
      return {
        chart: {
          height: 250,
          type: "radialBar",
        },
        colors: ["#82be00"],
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: 5,
                show: true,
                color: "#a1006b",
                fontSize: "24px",
              },
              value: {
                formatter: function (val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: false,
              },
            },
          },
        },

        labels: [this.pourcentage + "%"],
      };
    },

    seriesValidation() {
      return [
        {
          name: "Validation ",
          data: this.listePourcentage,
          color: "#a1006b",
        },
      ];
    },
    chartValidation() {
      return {
        chart: {
          height: 360,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + " %";
          },
          offsetY: 20,
          style: {
            fontSize: "16px",
            colors: ["#FFF"],
          },
        },

        xaxis: {
          categories: this.listeUp,
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: true,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      };
    },
    seriesDistance() {
      return [
        {
          name: "Km Parcourus ",
          data: this.listeKmParcourus,
          color: "#a1006b",
        },
      ];
    },
    chartDistance() {
      return {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          offsetX: 0,
          style: {
            fontSize: "12px",
            colors: ["#333"],
          },
        },
        xaxis: {
          categories: this.listeUp,
        },
      };
    },
    seriesConso() {
      return [
        {
          name: "Consommation ",
          data: this.listeCarburant,
          color: "#a1006b",
        },
      ];
    },
    chartConso() {
      return {
        chart: {
          height: 350,
          type: "radar",
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        colors: ["#FF4560"],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: "#FF4560",
          strokeWidth: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          categories: this.listeUp,
          labels: {
            style: {
              colors: ["#444444", "#444444", "#444444", "#444444", "#444444", "#444444", "#444444", "#444444"],
              fontSize: "11px",
              fontFamily: "Arial",
            },
          },
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
        },
      };
    },
    seriesDepense() {
      return [
        {
          name: "Péage (€)",
          type: "column",
          data: this.listePeage,
        },
        {
          name: "Lavage (€)",
          type: "column",
          data: this.listeLavage,
        },
        {
          name: "Autre (€)",
          type: "column",
          data: this.listeAutre,
        },
      ];
    },

    chartDepense() {
      return {
        chart: {
          type: "bar",
          height: 450,
        },
        colors: ["#b41765", "#c26690", "#d7616d"],
        dataLabels: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          offsetX: 0,
          style: {
            fontSize: "12px",
            colors: ["#333"],
          },
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.listeUp,
        },
        yaxis: {
          title: {
            text: "Euros ( € )",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " €";
            },
          },
        },
      };
    },
  },
  methods: {
    changeSelect() {
      this.$store.dispatch("getDashboardEtablissement", { userId: this.user.userId, month: this.mois, year: this.annee, site: this.user.site });
    },
  },
};
</script>

<style lang="scss">
.titre_print {
  font-size: 2.5rem;
}
</style>
