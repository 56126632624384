import { createStore } from "vuex";
const axios = require("axios");
const instance = axios.create({
baseURL : process.env.VUE_APP_BASE_URL,
});

let user = localStorage.getItem("user");
if (!user) {
  user = {
    userId: -1,
    token: "",
  };
} else {
  try {
    user = JSON.parse(user);
    instance.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  } catch {
    user = {
      userId: -1,
      token: "",
    };
  }
}

export default createStore({
  // ---------------------- S T A T E --------------------//
  state: {
    host : process.env.VUE_APP_HOST,
    title: "",
    status: "",
    errMessage: "",
    user: user,
    profil: "",
    allUnits: "",
    allAgents: "",
    allCards: "",
    allVehicules: "",
    vehicule: "",
    lastLogs: "",
    lastLogsUser: "",
    allLogsVehicle :"",
    allDepenses : "",
    validation : "",
    dashboard : "",
    allAcces : ""
  },

  // ---------------------- M U T A T I O N S --------------------//
  mutations: {
    title(state, title) {
      state.title = title;
    },
    setStatus(state, status) {
      state.status = status;
    },
    errMessage(state, error) {
      state.errMessage = error;
    },
    logUser(state, user) {
      instance.defaults.headers.common["Authorization"] = "Bearer " + user.token;
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
    },
    profil(state, profil) {
      state.profil = profil;
    },
    allUnits(state, allUnits) {
      state.allUnits = allUnits;
    },
    allAgents(state, allAgents) {
      state.allAgents = allAgents;
    },
    allCards(state, allCards) {
      state.allCards = allCards;
    },
    allVehicules(state, allVehicules) {
      state.allVehicules = allVehicules;
    },
    Vehicules(state, Vehicules) {
      state.vehicule = Vehicules;
    },
    LastLogs(state, LastLogs) {   
      state.lastLogs = LastLogs;
    },
    LastLogsUser(state, LastLogsUser) {   
      state.lastLogsUser = LastLogsUser;
    },
    allLogsVehicle(state, allLogsVehicle) {   
      state.allLogsVehicle = allLogsVehicle;
    },
    allDepenses(state, allDepenses) {   
      state.allDepenses = allDepenses;
    },
    validation(state, validation) {   
      state.validation = validation.length;
    },
    dashboard(state, dashboard) {   
      state.dashboard = dashboard;
    },
    allAcces(state, allAcces) {   
      state.allAcces = allAcces;
    },
    deleteStore(state) {
state.user = {
  userId: -1,
  token: "",
};
state.allAgents = "";
state.allCards = "";
state.allLogsVehicle = "";
state.allDepenses = "";
state.allUnits = "";
state.allVehicules = ""
state.profil = "";
state.vehicule = ""; 
state.lastLogsUser = "";   }
  },

  // ---------------------- A C T I O N S --------------------//
  actions: {
    login: async ({ commit }, loginInfos) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/auth/login", loginInfos);
        commit("setStatus", "");
        commit("logUser", response.data);
        return response.data;
      } catch (err) {
        
        commit("setStatus", "error_login");
        commit("errMessage", err.response.data);
        throw err;
      }
    },

    getProfil: async ({ commit, state }) => {
      const route = "/auth/".concat("", state.user.userId);
      try {
        const response = await instance.post(route, { userId: state.user.userId });
        commit("profil", response.data.user);
        return response.data.user;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    getAllUnits: async ({ commit, state }, data) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/getUnits", { userId: state.user.userId });
        commit("setStatus", "");
        commit("allUnits", response.data);
        return response.data;
      } catch (err) {
        commit("setStatus", "error_login");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    addAgent: async ({ state, commit, dispatch }, userInfos) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/auth/signup", userInfos);
        commit("setStatus", "");
        dispatch("getAllAgents", { userId: state.user.userId, id_unit: state.user.unit, id_role: state.user.role, id_site: state.user.site });
      } catch (err) {
        commit("setStatus", "error_login");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    updateAgent: async ({ state, commit, dispatch }, userInfos) => {
      const route = "/auth/".concat("", userInfos.agentId);
      try {
        commit("setStatus", "loading");
        const response = await instance.put(route, userInfos);
        commit("setStatus", "");
        dispatch("getAllAgents", { userId: state.user.userId, id_unit: state.user.unit, id_role: state.user.role, id_site: state.user.site });
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    changeMdp: async ({ commit }, data) => {
      const route = "/auth/mdp/".concat("", data.userId);
      try {
        commit("setStatus", "loading");
        const response = await instance.put(route, data);
        commit("setStatus", "");
      } catch (err) {
        commit("setStatus", "error_mdp");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    deleteUser: async ({ state, commit, dispatch }, agentId) => {
      const route = "/auth/delete/".concat("", agentId);
      try {
        commit("setStatus", "loading");
        const response = await instance.post(route, { userId: state.user.userId });
        commit("setStatus", "");
        dispatch("getAllAgents", { userId: state.user.userId, id_unit: state.user.unit, id_role: state.user.role, id_site: state.user.site });
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    getAllAgents: async ({ commit }, listAgents) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/auth", listAgents);
        commit("setStatus", "");
        commit("allAgents", response.data);
        return response.data;
      } catch (err) {
        commit("setStatus", "error_login");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getAllCards: async ({ commit, state }) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/allcards", { userId: state.user.userId, id_unit: state.user.unit, id_role: state.user.role, id_site: state.user.site });
        commit("setStatus", "");
        commit("allCards", response.data);
        return response.data;
      } catch (err) {
        commit("setStatus", "error_cards");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    addVehicules: async ({ state, commit, dispatch }, vehicules) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/vehicles", vehicules);
        commit("setStatus", "");
        dispatch("getAllVehicules", vehicules);
      } catch (err) {
        commit("setStatus", "error_vehicules");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getAllVehicules: async ({ commit, state }, vehicle) => {
      try {
        commit("setStatus", "loading");
        //const response = await instance.post("/journal/allVehicles", { userId: state.user.userId, id_unit: state.user.unit });
        const response = await instance.post("/journal/allVehicles", vehicle);

        commit("setStatus", "");
        commit("allVehicules", response.data);
        return response.data;
      } catch (err) {
        commit("setStatus", "error_vehicules");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getOneVehicules: async ({ commit, state }, id) => {
      const route = "/journal/vehicles/".concat("", id);
      try {
        commit("setStatus", "loading");
        const response = await instance.post(route, { userId: state.user.userId });
        commit("setStatus", "");
        commit("Vehicules", response.data);
        return response.data;
      } catch (err) {
        commit("setStatus", "error_vehicules");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    deleteVehicule: async ({ state, commit, dispatch }, vehiculeInfos) => {
      const route = "/journal/vehicles/delete/".concat("", vehiculeInfos.idVehicle);
      try {
        commit("setStatus", "loading");
        const response = await instance.put(route, vehiculeInfos);
        commit("setStatus", "");
        dispatch("getAllVehicules", vehiculeInfos);
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    updateVehicule: async ({ state, commit, dispatch }, vehiculeInfos) => {
      const route = "/journal/vehicles/".concat("", vehiculeInfos.idVehicle);
      try {
        commit("setStatus", "loading");
        const response = await instance.put(route, vehiculeInfos);
        commit("setStatus", "");
        dispatch("getAllVehicules", vehiculeInfos);
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    addCards: async ({ state, commit, dispatch }, cards) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/cards", cards);
        commit("setStatus", "");
        dispatch("getAllCards");
      } catch (err) {
        commit("setStatus", "error_card");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    deleteCards: async ({ state, commit, dispatch }, cardInfos) => {
      const route = "/journal/cards/delete/".concat("", cardInfos.idCard);
      try {
        commit("setStatus", "loading");
        const response = await instance.put(route, cardInfos);
        commit("setStatus", "");
        dispatch("getAllCards");
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    updateCards: async ({ state, commit, dispatch }, cardInfos) => {
      const route = "/journal/cards/".concat("", cardInfos.idCard);
      try {
        commit("setStatus", "loading");
        const response = await instance.put(route, cardInfos);
        commit("setStatus", "");
        dispatch("getAllCards");
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getLastLogs: async ({ state, commit }, id) => {    
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/lastLogs", { userId: state.user.userId, id_vehicle: id });
        commit("setStatus", ""); 
        commit("LastLogs", response.data);     
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getLastLogsUser: async ({ state, commit, dispatch }, data) => {  
          try {
          commit("setStatus", "loading");
          const response = await instance.post("/journal/lastLogsUser", { userId: state.user.userId, cp: state.user.cp});
          commit("setStatus", "");   
          commit("LastLogsUser", response.data);
         // dispatch("getOneVehicules", response.data.id_vehicle);  
          //dispatch("getAllVehicules", { userId: state.user.userId, id_unit: response.data.id_unit }); 
          //dispatch("getAllLogsVehicle", { month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: response.data.id_vehicle });
        //   dispatch("getAllExpenses", { month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: response.data.id_vehicle });       
        //  dispatch("getValidation", { userId: state.user.userId, month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: response.data.id_vehicle });
        } catch (err) {
          commit("setStatus", "error_update");
          commit("errMessage", err.response.data);
          throw err;
        }


    },
    addLogs: async ({ commit }, logs) => {    
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/logs", logs);
        commit("setStatus", "");  
      } catch (err) {
        commit("setStatus", "error_newjs");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    updateLogs: async ({ commit }, logs) => {   
      const route = "/journal/logs/".concat("", logs.idLog);
      try {
        commit("setStatus", "loading");
        const response = await instance.put(route, logs);
        commit("setStatus", "");
        // dispatch("getAllCards");
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    deleteLogs: async ({ state, commit}, id) => {
      const route = "/journal/logs/delete/".concat("", id);
      try {
        commit("setStatus", "loading");
        const response = await instance.post(route, { userId: state.user.userId, id });
        commit("setStatus", "");
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getAllLogsVehicle: async ({ commit, state }, logs) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/logs/vehicle", { userId: state.user.userId, month : logs.month,  year: logs.year, id_vehicle: logs.id_vehicle});
        commit("setStatus", "");
        commit("allLogsVehicle", response.data);
        return response.data;
      } catch (err) {
        commit("setStatus", "error_logVehicle");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    addDepense: async ({ commit }, depense) => {            
      const data = new FormData();
      for (const [key, value] of Object.entries(depense)) {
        data.append(key, value);
      }     
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/expenses", data);
        commit("setStatus", "");
        // return dispatch("getAllStories");
      }
      catch (error) {
        commit("setStatus", "error_depense");
        commit("errMessage", error.response.data);
        throw (error);
      };

    },
    getAllExpenses: async ({ commit, state }, exp) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/getexpenses", { userId: state.user.userId, month : exp.month,  year: exp.year, id_vehicle: exp.id_vehicle});
        commit("setStatus", "");
        commit("allDepenses", response.data);
        return response.data;
      } catch (err) {
        commit("setStatus", "error_depenses");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    updateDepense: async ({ commit }, exp) => {  
      const route = "/journal/expenses/".concat("", exp.idExpense);
      try {
        commit("setStatus", "loading");
        const response = await instance.put(route, exp);
        commit("setStatus", "");
        // dispatch("getAllCards");
      } catch (err) {
        commit("setStatus", "error_depense");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    deleteDepense: async ({ state, commit}, exp) => {
      const route = "/journal/expenses/delete/".concat("", exp.idExpense);

      try {
        commit("setStatus", "loading");
        const response = await instance.post(route, { userId: state.user.userId, exp });
        commit("setStatus", "");
      } catch (err) {
        commit("setStatus", "error_update");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    validationJs: async ({ commit }, js) => {       
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/validation", js);
        commit("setStatus", "");  
      } catch (err) {
        commit("setStatus", "error_validation");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getValidation: async ({ commit }, validation) => { 
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/allvalidation", validation );
        commit("setStatus", "");
       commit("validation", response.data) 
      } catch (err) {
        commit("setStatus", "error_validation");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getDashboardEtablissement: async ({ commit }, dash) => { 
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/dashboard/", { userId: dash.userId, month: dash.month,  year: dash.year, site: dash.site});
        commit("setStatus", "");
        commit("dashboard", response.data) 
      } catch (err) {
        commit("setStatus", "error_dashboard");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    deleteValidation: async ({ state, commit }, id) => {   
      const route = "/journal/validation/delete/".concat("", id);
      try {
        commit("setStatus", "loading");
        const response = await instance.post(route, { userId: state.user.userId, id });
        commit("setStatus", "");
      } catch (err) {
        commit("setStatus", "error_dashboard");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getDashboardUnite: async ({ commit }, dash) => { 
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/journal/dashboard/", { userId: dash.userId, month: dash.month,  year: dash.year, site: dash.site, unite: dash.unite});
        commit("setStatus", "");
        commit("dashboard", response.data) 
      } catch (err) {
        commit("setStatus", "error_dashboard");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    getAllAcces: async ({ commit, state }) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/acces/getAllAcces", { userId: state.user.userId });
        commit("setStatus", "");
        commit("allAcces", response.data);
        return response.data;
      } catch (err) {
        commit("setStatus", "error_acces");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    envoiMail: async ({ commit, state }, data) => {
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/email", { userId: state.user.userId, from : data.from, to : data.to, subject : data.subject, html : data.html, attachments : data.attachments });
        commit("setStatus", "");
        return response.data;
      } catch (err) {
        commit("setStatus", "error_email");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    exportPdf: async ({ commit, state }, data) => {
      
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/pdf", { userId: state.user.userId, data : data });
        commit("setStatus", "");
        return response.data;
      } catch (err) {
        commit("setStatus", "error_pdf");
        commit("errMessage", err.response.data);
        throw err;
      }
    },
    exportQrCode: async ({ commit, state }, data) => {
      
      try {
        commit("setStatus", "loading");
        const response = await instance.post("/qrcode", { userId: state.user.userId, data : data });
        commit("setStatus", "");
        return response.data;
      } catch (err) {
        commit("setStatus", "error_pdf");
        commit("errMessage", err.response.data);
        throw err;
      }
    },

  },
});
