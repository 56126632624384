<template>
  <div>
    <table id="tableCards" class="table hover" style="width: 100%">
      <thead>
        <tr>
          <th>Numéro</th>
          <th>Nom</th>
          <th>Unité</th>
          <th>Date Expiration</th>
          <th><b-icon-three-dots /></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(card, index) in allCards" :key="index">
          <td>{{ card.cardNumber }}</td>
          <td>{{ card.cardName }}</td>
          <td>{{ card.nameUnit }}</td>
          <td>{{ card.dateExpFr }}</td>
          <td>
            <button type="button" class="btn btn-only-icon btn-secondary" @click="Modal(card)">
              <b-icon-pencil-square />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal  -->
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="display-1">Carte : {{ card.cardName }}</h1>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="!showDelete" class="modal-body">
            <form>
              <div class="form-row">
                <div class="col-12 mb-3" v-if="user.role == 1">
                  <label for="secteur" class="font-weight-medium mb-2 required">Choix du secteur</label>
                  <select id="secteur" v-model="unite" class="form-control">
                    <option value="" disabled hidden>Choisissez votre unité</option>
                    <option v-for="(unit, index) in allUnits" :key="index" :value="unit.idUnit" :data="unit.idUnit">{{ unit.nameUnit }}</option>
                  </select>
                </div>

                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="num">Numéro de carte</label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="num" placeholder="Numéro de carte" autocomplete="off" v-model="num" />
                    <span class="form-control-state"></span>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="nom">Nom de la carte</label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="nom" placeholder="Nom de la carte" autocomplete="off" v-model="name" aria-describedby="invalidnom" />
                    <span class="form-control-state"></span>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 mb-3">
                  <label for="dateMad" class="font-weight-medium mb-2">Date de mise à disposition</label>
                  <v-date-picker v-model="dateMad" :model-config="modelConfig">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                    </template>
                  </v-date-picker>
                </div>

                <div class="col-md-6 col-sm-6 mb-3">
                  <label for="dateExp" class="font-weight-medium mb-2">Date d'expiration</label>
                  <v-date-picker v-model="dateExp" :model-config="modelConfig">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="form-error mt-3" v-if="status == 'error_update'">
                <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                  <li>{{ err.message }}</li>
                </ul>
              </div>
            </form>
          </div>
          <div v-else class="modal-body">
            <form>
              <div class="form-row">
                <div class="col-12 mb-3">
                  <label for="dateRest" class="font-weight-medium mb-2">Date de restitution</label>
                  <v-date-picker v-model="dateRest" :model-config="modelConfig">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="form-error mt-3" v-if="status == 'error_update'">
                <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                  <li>{{ err.message }}</li>
                </ul>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div v-if="!showDelete" class="col-12 col-sm-6 mb-3">
                <button :disabled="!validatedFields" class="btn btn-block btn-primary" @click="updateCards()">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>Modifier</span>
                </button>
              </div>
              <div v-if="!showDelete" class="col-12 col-sm-6 mb-3">
                <button type="button" class="btn btn-block btn-danger" @click="this.showDelete = true">Supprimer</button>
              </div>
              <div v-if="showDelete" class="col-12 col-sm-6 mb-3">
                <button type="button" class="btn btn-block btn-primary" @click="this.showDelete = flase">Retour</button>
              </div>
              <div v-if="showDelete" class="col-12 col-sm-6 mb-3">
                <button type="button" :disabled="!validatedSupp" class="btn btn-block btn-danger" @click="deleteCards()">Supprimer</button>
              </div>
              <div class="col-sm-12 mb-3">
                <button type="button" class="btn btn-block btn-secondary" @click="close()">Fermer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "Table_Cards",
  data() {
    return {
      showDelete: false,
      card: {},
      valueNum: null,
      valueName: null,
      valueDateMad: null,
      valueDateExp: null,
      valueDateRest: null,
      valueUnite: null,
      dateRest: null,
      dateMadFr: null,
      dateExpFr: null,
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
    };
  },
  created() {
    this.$store.dispatch("getAllCards");
  },
  beforeUpdate() {
    this.destroyTable();
  },
  updated() {
    this.getTableCards();
  },
  methods: {
    Modal(card) {
      this.card = card;
      const dateMad = new Date(this.card.startingDate);
      this.dateMadFr = ("0" + dateMad.getDate()).slice(-2) + "/" + ("0" + (dateMad.getMonth() + 1)).slice(-2) + "/" + dateMad.getFullYear();
      const dateExp = new Date(this.card.expirationDate);
      this.dateExpFr = ("0" + dateExp.getDate()).slice(-2) + "/" + ("0" + (dateExp.getMonth() + 1)).slice(-2) + "/" + dateExp.getFullYear();

      jQuery("#myModal").modal({
        keyboard: true,
        toggle: true,
      });
    },
    close() {
      this.$store.commit("setStatus", "");
      this.valueNum = null;
      this.valueName = null;
      this.valueDateMad = null;
      this.valueDateExp = null;
      this.dateMadFr = null;
      this.dateExpFr = null;
      this.dateRest = null;
      this.showDelete = false;

      jQuery("#myModal").modal("hide");
    },
    updateCards() {
      if (this.valueNum === null) {
        this.valueNum = this.card.cardNumber;
      }
      if (this.valueName === null) {
        this.valueName = this.card.cardName;
      }
      if (this.valueDateMad === null) {
        this.valueDateMad = this.dateMadFr;
      }
      if (this.valueDateExp === null) {
        this.valueDateExp = this.dateExpFr;
      }
      if (this.valueUnite === null) {
        this.valueUnite = this.card.idUnit;
      }
      const self = this;
      this.$store
        .dispatch("updateCards", {
          cardNumber: this.valueNum,
          cardName: this.valueName,
          id_unit: this.valueUnite,
          startingDate: this.valueDateMad.split("/").reverse().join("-"),
          expirationDate: this.valueDateExp.split("/").reverse().join("-"),
          idCard: this.card.idCard,
          userId: this.user.userId,
        })
        .then(function () {
          self.close();
          self.showAlert({
            icon: "success",
            title: "Modification réalisée avec succès.",
          });
        });
    },
    deleteCards() {
      const self = this;
      this.$store
        .dispatch("deleteCards", {
          date_resti: this.dateRest.split("/").reverse().join("-"),
          idCard: this.card.idCard,
          userId: this.user.userId,
        })
        .then(function () {
          self.close();
          jQuery("#myModal").modal("hide");
          self.showAlert({
            icon: "success",
            title: self.card.cardName + " a été supprimé avec succès.",
          });
        });
    },
    destroyTable() {
      $("#tableCards").DataTable().destroy();
    },
    getTableCards() {
      $("#tableCards").DataTable({
        language: {
          emptyTable: "No data available in table",
          search: "Recherche ",
          paginate: {
            first: "Premier",
            last: "Dernier",
            next: "Suivant",
            previous: "Précédent",
          },
          lengthMenu: "Voir _MENU_ membres",
        },
        retrieve: true,
        searching: true,
        info: false,
        scrollY: "400px",
        scrollX: true,
        scrollCollapse: true,
        paging: false,
      });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
      });
    },
  },
  computed: {
    ...mapState(["allUnits", "status", "errMessage", "user", "allCards"]),
    validatedFields() {
      return this.num != "" && this.name != "" && this.dateMad != "" && this.dateExp != "" ? true : false;
    },
    validatedSupp() {
      return this.dateRest != null ? true : false;
    },
    num: {
      get() {
        return this.valueNum === null ? this.card.cardNumber : this.valueNum;
      },
      set(value) {
        this.valueNum = value;
      },
    },
    name: {
      get() {
        return this.valueName === null ? this.card.cardName : this.valueName;
      },
      set(value) {
        this.valueName = value;
      },
    },
    unite: {
      get() {
        return this.valueUnite === null ? this.card.id_unit : this.valueUnite;
      },
      set(value) {
        this.valueUnite = value;
      },
    },
    dateMad: {
      get() {
        return this.valueDateMad === null ? this.dateMadFr : this.valueDateMad;
      },
      set(value) {
        this.valueDateMad = value;
      },
    },
    dateExp: {
      get() {
        return this.valueDateExp === null ? this.dateExpFr : this.valueDateExp;
      },
      set(value) {
        this.valueDateExp = value;
      },
    },
  },
};
</script>

<style></style>
