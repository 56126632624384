<template>
  <div>
    <table id="tableUser" class="table hover" style="width: 100%">
      <thead>
        <tr>
          <th class="text-center">Immatriculation</th>
          <th class="text-center">Unité</th>
          <th class="text-center">Km Véhicule</th>
          <th class="text-center">Km Parcourus</th>
          <th class="text-center">Carburant</th>
          <th class="text-center">Péage</th>
          <th class="text-center">Lavage</th>
          <th class="text-center">Autre</th>
          <th class="text-center">Taux</th>
          <th class="text-center">JS validée</th>
          <th class="text-center"><b-icon-three-dots /></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(dash, index) in dashboard" :key="index">
          <td class="text-center">{{ dash.registration }}</td>
          <td class="text-center">{{ dash.nameUnit }}</td>
          <td class="text-center">{{ dash.kmCompteur }}</td>
          <td class="text-center">{{ dash.totalMois }}</td>
          <td class="text-center" v-if="dash.litres">{{ dash.litres }}</td>
          <td class="text-center" v-else>-</td>
          <td class="text-center" v-if="dash.peage">{{ dash.peage }} €</td>
          <td class="text-center" v-else>-</td>
          <td class="text-center" v-if="dash.lavage">{{ dash.lavage }} €</td>
          <td class="text-center" v-else>-</td>
          <td class="text-center" v-if="dash.autre">{{ dash.autre }} €</td>
          <td class="text-center" v-else>-</td>
          <td class="text-center" v-if="dash.nbDayUse">{{ dash.nbDayUse }} %</td>
          <td class="text-center" v-else>-</td>
          <td class="text-center">
            <div v-if="dash.validation > 0"><b-icon-check style="font-size: 2rem" /><div style="font-size: 0rem">.</div></div>
          </td>
          <td class="text-center">
            <div v-if="dash.validation > 0">
              <button type="button" class="btn btn-only-icon btn-danger" @click="Modal(dash.registration, dash.validation)">
                <b-icon-x-lg />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal  -->
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="display-1">Véhicule : {{ immat }}</h1>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body my-3 text-center">
            <div class="font-weight-medium mb-2">Voulez-vous rejeter le journal de service du véhicule immatriculé {{ immat }} du mois de {{ lettreMois }} {{ annee }} ?</div>
          </div>
          <div class="modal-footer justify-content-end">
            <div class="row">
              <div class="col mb-3">
                <button type="button" class="btn btn-block btn-secondary" data-dismiss="modal">Fermer</button>
              </div>
              <div class="col mb-3">
                <button type="button" class="btn btn-block btn-danger" @click="rejeter(this.idValidation)">Rejeter</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "Table_Etablissement",
  props: ["dashboard", "mois", "annee", "lettreMois"],
  data() {
    return {
      immat: "",
      idValidation: "",
    };
  },
  beforeUpdate() {
    this.destroyTable();
  },
  updated() {
    this.getTableUser();
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    Modal(immat, id) {
      this.immat = immat;
      this.idValidation = id;
      jQuery("#myModal").modal({
        keyboard: true,
        toggle: true,
      });
    },
    destroyTable() {
      $("#tableUser").DataTable().destroy();
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
      });
    },
    getTableUser() {
      $("#tableUser").DataTable({
        language: {
          emptyTable: "No data available in table",
          search: "Recherche ",
          paginate: {
            first: "Premier",
            last: "Dernier",
            next: "Suivant",
            previous: "Précédent",
          },
          lengthMenu: "Voir _MENU_ membres",
        },
        retrieve: true,
        searching: true,
        info: false,
        scrollY: "400px",
        scrollX: true,
        scrollCollapse: true,
        paging: false,
      });
    },
    rejeter(id) {
      const self = this;
      this.$store.dispatch("deleteValidation", id).then(function () {
        self.$store.dispatch("getDashboardEtablissement", { userId: self.user.userId, month: self.mois, year: self.annee, site: self.user.site });
        jQuery("#myModal").modal("hide");
        self.showAlert({
          icon: "success",
          title: self.immat + " a été rejeté avec succès.",
        });
      });
    },
  },
};
</script>

<style></style>
