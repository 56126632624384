<template>
  <body class="d-flex flex-column justify-content-between">
    <Navbar />
    <router-view />
    <Footer class="d-print-none" />
  </body>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Journal de Service",
  components: { Navbar, Footer },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  max-height: 100vh;
}
</style>
