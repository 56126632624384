<template>
  <div>
    <table id="tableVehicules" class="table hover" style="width: 100%">
      <thead>
        <tr>
          <th>Immatriculation</th>
          <th>Constructeur</th>
          <th>Model</th>
          <th>Unité</th>
          <th>Carte essence</th>
          <th><b-icon-three-dots /></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vehicule, index) in allVehicules" :key="index">
          <td>{{ vehicule.registration }}</td>
          <td>{{ vehicule.carMaker }}</td>
          <td>{{ vehicule.carModel }}</td>
          <td>{{ vehicule.nameUnit }}</td>
          <td>{{ vehicule.cardName }}</td>
          <td>
            <button type="button" class="btn btn-only-icon btn-secondary" @click="Modal(vehicule)">
              <b-icon-pencil-square />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal  -->
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="display-1">Véhicule : {{ vehicule.registration }}</h1>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="!showDelete" class="modal-body">
            <form>
              <div class="form-row">
                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="numerocp">Immatriculation</label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="numerocp" placeholder="Immatriculation" autocomplete="off" v-model="immatriculation" />
                    <span class="form-control-state"></span>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="nom">Constructeur</label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="nom" placeholder="Contructeur" autocomplete="off" v-model="constructeur" />
                    <span class="form-control-state"></span>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="model">Model </label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="model" placeholder="Model" autocomplete="off" v-model="model" />
                    <span class="form-control-state"></span>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 mb-3">
                  <label for="secteur" class="font-weight-medium mb-2 required">Carte Essence</label>
                  <select id="secteur" v-model="essence" class="form-control">
                    <option value="" disabled hidden>Choisissez votre carte</option>
                    <option v-for="(card, index) in allCards" :key="index" :value="card.idCard" :data="card.idCard">{{ card.cardName }}</option>
                  </select>
                </div>
                <div class="col-md-6 col-sm-6 mb-3">
                  <label for="dateMad" class="font-weight-medium mb-2">Date de mise à disposition</label>
                  <v-date-picker locale="fr" v-model="dateMad" :model-config="modelConfig">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="form-error mt-3" v-if="status == 'error_update'">
                <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                  <li>{{ err.message }}</li>
                </ul>
              </div>
            </form>
          </div>
          <div v-else class="modal-body">
            <form>
              <div class="form-row">
                <div class="col-md-6 col-sm-6 mb-3">
                  <label for="dateRest" class="font-weight-medium mb-2">Date de restitution</label>
                  <v-date-picker locale="fr" v-model="dateRest" :model-config="modelConfig">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="form-error mt-3" v-if="status == 'error_update'">
                <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                  <li>{{ err.message }}</li>
                </ul>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div v-if="!showDelete" class="col-12 col-sm-6 mb-3">
                <button :disabled="!validatedFields" class="btn btn-block btn-primary" @click="updateVehicule()">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>Modifier</span>
                </button>
              </div>
              <div v-if="!showDelete" class="col-12 col-sm-6 mb-3">
                <button type="button" class="btn btn-block btn-danger" @click="this.showDelete = true">Supprimer</button>
              </div>
              <div v-if="showDelete" class="col-12 col-sm-6 mb-3">
                <button type="button" class="btn btn-block btn-primary" @click="this.showDelete = flase">Retour</button>
              </div>
              <div v-if="showDelete" class="col-12 col-sm-6 mb-3">
                <button type="button" :disabled="!validatedSupp" class="btn btn-block btn-danger" @click="deleteVehicule()">Supprimer</button>
              </div>
              <div class="col-sm-12 mb-3">
                <button type="button" class="btn btn-block btn-secondary" @click="close()">Fermer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "Table_Vehicules",
  data() {
    return {
      showDelete: false,
      vehicule: {},
      valueImmatriculation: null,
      valueConstructeur: null,
      valueModel: null,
      valueEssence: null,
      valueDateMad: null,
      dateRest: null,
      dateFr: null,
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
    };
  },

  beforeUpdate() {
    this.destroyTable();
  },
  updated() {
    this.getTableVehicules();
  },

  created() {
    this.$store.dispatch("getAllVehicules", { userId: this.user.userId, id_unit: this.user.unit });
  },
  methods: {
    Modal(vehicule) {
      this.vehicule = vehicule;
      const date = new Date(this.vehicule.date_acqui);
      this.dateFr = ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
      jQuery("#myModal").modal({
        keyboard: true,
        toggle: true,
      });
    },
    close() {
      this.$store.commit("setStatus", "");
      this.valueImmatriculation = null;
      this.valueConstructeur = null;
      this.valueModel = null;
      this.valueEssence = null;
      this.valueDateMad = null;
      this.showDelete = false;
      jQuery("#myModal").modal("hide");
    },
    updateVehicule() {
      if (this.valueImmatriculation === null) {
        this.valueImmatriculation = this.vehicule.registration;
      }
      if (this.valueConstructeur === null) {
        this.valueConstructeur = this.vehicule.carMaker;
      }
      if (this.valueModel === null) {
        this.valueModel = this.vehicule.carModel;
      }
      if (this.valueEssence === null) {
        this.valueEssence = this.vehicule.id_card;
      }
      if (this.valueDateMad === null) {
        this.valueDateMad = this.dateFr;
      }
      const self = this;
      this.$store
        .dispatch("updateVehicule", {
          registration: this.valueImmatriculation.toUpperCase(),
          carMaker: this.valueConstructeur.toUpperCase(),
          carModel: this.valueModel.toUpperCase(),
          status: 1,
          available: 1,
          id_unit: this.user.unit,
          id_card: this.valueEssence,
          date_acqui: this.valueDateMad.split("/").reverse().join("-"),
          idVehicle: this.vehicule.idVehicle,
          userId: this.user.userId,
        })
        .then(function () {
          self.valueImmatriculation = null;
          self.valueConstructeur = null;
          self.valueModel = null;
          self.valueEssence = null;
          self.valueDateMad = null;
          self.dateFr = null;
          self.destroyTable();
          jQuery("#myModal").modal("hide");
          self.showAlert({
            icon: "success",
            title: "Modification réalisée avec succès.",
          });
        });
    },
    deleteVehicule() {
      const self = this;
      this.$store
        .dispatch("deleteVehicule", {
          date_resti: this.dateRest.split("/").reverse().join("-"),
          idVehicle: this.vehicule.idVehicle,
          userId: this.user.userId,
          id_unit: this.user.unit,
        })
        .then(function () {
          self.dateRest = null;
          self.showDelete = false;
          self.destroyTable();
          jQuery("#myModal").modal("hide");
          self.showAlert({
            icon: "success",
            title: self.vehicule.registration + " a été supprimé avec succès.",
          });
        });
    },
    destroyTable() {
      $("#tableVehicules").DataTable().destroy();
    },
    getTableVehicules() {
      $("#tableVehicules").DataTable({
        language: {
          emptyTable: "No data available in table",
          search: "Recherche ",
          paginate: {
            first: "Premier",
            last: "Dernier",
            next: "Suivant",
            previous: "Précédent",
          },
          lengthMenu: "Voir _MENU_ membres",
        },
        retrieve: true,
        searching: true,
        info: false,
        scrollY: "400px",
        scrollX: true,
        scrollCollapse: true,
        paging: false,
      });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
        //footer: param.footer,
      });
    },
  },
  computed: {
    ...mapState(["allVehicules", "status", "errMessage", "user", "allCards"]),
    validatedFields() {
      return this.immatriculation != "" && this.constructeur != "" && this.model != "" && this.essence != "" && this.dateMad != "" ? true : false;
    },
    validatedSupp() {
      return this.dateRest != null ? true : false;
    },
    immatriculation: {
      get() {
        return this.valueImmatriculation === null ? this.vehicule.registration : this.valueImmatriculation;
      },
      set(value) {
        this.valueImmatriculation = value;
      },
    },
    constructeur: {
      get() {
        return this.valueConstructeur === null ? this.vehicule.carMaker : this.valueConstructeur;
      },
      set(value) {
        this.valueConstructeur = value;
      },
    },
    model: {
      get() {
        return this.valueModel === null ? this.vehicule.carModel : this.valueModel;
      },
      set(value) {
        this.valueModel = value;
      },
    },
    essence: {
      get() {
        return this.valueEssence === null ? this.vehicule.id_card : this.valueEssence;
      },
      set(value) {
        this.valueEssence = value;
      },
    },
    dateMad: {
      get() {
        return this.valueDateMad === null ? this.dateFr : this.valueDateMad;
      },
      set(value) {
        this.valueDateMad = value;
      },
    },
  },
};
</script>

<style></style>
