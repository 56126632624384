<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">{{ profil.site }} - {{ profil.nameUnit }}</h5>
            <div class="card-body">
              <h5 class="card-title mb-3">Ajouter un agent :</h5>

              <form @submit.prevent="addAgent()">
                <div class="form-row">
                  <div class="col mb-3" v-if="user.role == 1">
                    <label for="secteur" class="font-weight-medium mb-2 required">Choix du secteur</label>
                    <select id="secteur" v-model="unite" class="form-control">
                      <option value="" disabled hidden>Choisissez votre unité</option>
                      <option v-for="(unit, index) in allUnits" :key="index" :value="unit.idUnit" :data="unit.idUnit">{{ unit.nameUnit }}</option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="numerocp">Numéro de CP</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="numerocp" placeholder="Numéro CP" autocomplete="off" v-model="numerocp" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="nom">Nom </label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="nom" placeholder="Nom" autocomplete="off" v-model="nom" aria-describedby="invalidnom" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="prenom">Prénom </label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="prenom" placeholder="Prénom" autocomplete="off" v-model="prenom" aria-describedby="invalidprenom" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="email">Email </label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="email" placeholder="prénom.nom@reseau.sncf.fr" autocomplete="off" v-model="email" aria-describedby="invalidemail" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                </div>

                <div class="form-error mt-3" v-if="status == 'error_login'">
                  <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                  <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                    <li>{{ err.message }}</li>
                  </ul>
                </div>

                <button class="btn btn-primary mt-3 float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>VALIDER</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Liste des agents</h5>

            <div class="card-body">
              <TableUsers />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import TableUsers from "@/components/Tables/Table_Users.vue";
import $ from "jquery";

export default {
  name: "Agents",
  components: { TableUsers },
  data() {
    return {
      numerocp: "",
      nom: "",
      prenom: "",
      email: "",
      unite: "",
    };
  },
  mounted() {
    this.$store.commit("title", {
      desktop: "Gestion des agents",
      mobile: "Agents",
    });
    this.$store.dispatch("getProfil");
    this.$store.dispatch("getAllUnits");
  },

  methods: {
    addAgent() {
      if (this.unite == "") {
        this.unite = parseInt(this.user.unit);
      } else {
        this.unite = parseInt(this.unite);
      }
      const self = this;
      this.$store
        .dispatch("addAgent", {
          nom: this.nom.toUpperCase(),
          prenom: this.prenom,
          cp: this.numerocp.toUpperCase(),
          email: this.email,
          id_unit: this.unite,
          id_site: this.user.site,
          id_role: 3,
          userId: this.user.userId,
        })
        .then(function () {
          $("#tableUser").DataTable().destroy();
          self.showAlert({
            icon: "success",
            title: self.nom + " " + self.prenom + " a été ajouté avec succès.",
            footer: self.profil.society + "  -  " + self.profil.site,
          });
          self.nom = "";
          self.prenom = "";
          self.numerocp = "";
          self.email = "";
          self.unite = "";
        });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
        // footer: param.footer,
      });
    },
  },
  computed: {
    validatedFields() {
      if (this.user.role == 1) {
        return this.numerocp != "" && this.nom != "" && this.prenom != "" && this.email != "" && this.unite != "" ? true : false;
      } else {
        return this.numerocp != "" && this.nom != "" && this.prenom != "" && this.email != "" ? true : false;
      }
    },
    ...mapState(["status", "errMessage", "profil", "user", "allUnits", "allAgents"]),
  },
};
</script>

<style></style>
