import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Journal from "@/views/js/Journal.vue";
import Acces from "@/views/Acces.vue";
import Newjs from "@/views/js/NewJs.vue";
import Update from "@/views/js/Update.vue";
import Depense from "@/views/js/Depense.vue";
import Agents from "@/views/Parametres/Agents.vue";
import Vehicules from "@/views/Parametres/Vehicules.vue";
import Cartes from "@/views/Parametres/Cartes.vue";
import Compte from "@/views/compte/mdp.vue";
import Etablissement from "@/views/dashboard/etablissement.vue";
import Unite from "@/views/dashboard/unite.vue";
import Login from "@/views/Login.vue";
import About from "@/views/About.vue";
import NotFound from "@/views/Notfound.vue";
import Lock from "../views/Lock.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Autope",
      requiresAuth: true,
      //isAdminEtablissement: true
    },
  },
  {
    path: "/dashboard/etablissement",
    name: "Etablissement",
    component: Etablissement, 
    meta: {
      title: "Dashboard - Etablissement",
      requiresAuth: true,
      isAdminEtablissement: true
    },
  },
  {
    path: "/dashboard/unite",
    name: "Unite",
    component: Unite, 
    meta: {
      title: "Dashboard - Unité",
      requiresAuth: true,
      isAdminUnite: true
    },
  },
  {
    path: "/js/journal",
    name: "Journal",
    component: Journal, 
    meta: {
      title: "Autope - JS",
      requiresAuth: true,
      // isAdmin: true
    },
  },
  {
    path: "/acces",
    name: "Acces",
    component: Acces, 
    meta: {
      title: "Paris Est - Accès",
      requiresAuth: true,
      // isAdmin: true
    },
  },
  {
    path: "/js/NewJs/:id",
    name: "NewJs",
    component: Newjs,
    props: true,
    meta: {
      title: "Autope - Trajet",
      requiresAuth: true,
      // isAdmin: true
    },
  },
  {
    path: "/js/Update",
    name: "Update",
    component: Update,
    props: true,
    meta: {
      title: "Autope -  Modification",
      requiresAuth: true,
      // isAdmin: true
    },
  },
  {
    path: "/js/Depense",
    name: "Depense",
    component: Depense,
    props: true,
    meta: {
      title: "Autope -  Dépenses",
      requiresAuth: true,
      // isAdmin: true
    },
  },
  {
    path: "/parametres/agents",
    name: "Agents",
    component: Agents,
    meta: {
      title: "Autope - Agents",
      requiresAuth: true,
      isAdminUnite: true
    },
  },
  {
    path: "/parametres/vehicules",
    name: "Vehicules",
    component: Vehicules,
    meta: {
      title: "Autope - Véhicules",
      requiresAuth: true,
      isAdminUnite: true,
    },
  },
  {
    path: "/parametres/cartes",
    name: "Cartes",
    component: Cartes,
    meta: {
      title: "Autope - Cartes essence",
      requiresAuth: true,
      isAdminUnite: true,
    },
  },
  {
    path: "/compte/changeMdp",
    name: "Compte",
    component: Compte,
    meta: {
      title: "Autope - Mon Compte",
      requiresAuth: true,
      //isAdmin: true,
    },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Autope - Login",
      requiresAuth: false,
    },
  },

  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "Autope - About",
      requiresAuth: false,
    },
  },
  {
    path: "/lock",
    name: "Lock",
    component: Lock,
    meta: {
      title: "Locked Page",
    },
  },
  {
    path: "/:patchMatch(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 Not Found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});







router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const start = store.state.user.start;
    let now = Date.now();
    let diff = now - start;
    // Vérification si le Token à été crée il y a plus de 24H.
    if (diff >= 86400000 || isNaN(diff)) {
      localStorage.removeItem("user");
      store.state.user = {
        userId: -1,
        token: "",
      };
     next({ path: '/login', query: { redirect: to.fullPath } });
    }
  }
  

  if (to.matched.some((record) => record.meta.isAdminEtablissement)) {
    if (store.state.user.role != 1) {
      next({ path: "/lock" });
    }
    else {
      next(); //requiresAuth = false
    }
  }


  if (to.matched.some((record) => record.meta.isAdminUnite)) {
    if (store.state.user.role > 2) {  
      next({ path: "/lock" });
    }
    else {
      next(); //requiresAuth = false
    }
  }
  
  
  else {
    next(); //requiresAuth = false
  }



});

router.afterEach((to) => {
  document.title = to.meta.title;
});

export default router;
