<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-white rounded-top text-primary text-uppercase">Modification :</h5>

            <div class="card-body">
              <form @submit.prevent="updateJs()">
                <div class="form-row">
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label for="datedep" class="font-weight-medium mb-2">Date départ :</label>
                    <v-date-picker locale="fr" v-model="datedep" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2" for="heuredep">Heure départ :</label>
                    <select id="heuredep" v-model="heuredep" class="form-control">
                      <option v-for="(heure, index) in choixHeure" :key="index" :value="heure" :data="heure">{{ heure }}</option>
                    </select>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label for="dateret" class="font-weight-medium mb-2">Date retour :</label>
                    <v-date-picker locale="fr" v-model="dateret" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2" for="heureret">Heure retour :</label>
                    <select id="heureret" v-model="heureret" class="form-control">
                      <option v-for="(heure, index) in choixHeure" :key="index" :value="heure" :data="heure">{{ heure }}</option>
                    </select>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="kmdep">Km départ :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="kmdep" placeholder="" autocomplete="off" v-model="kmdep" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="kmret">Km retour :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="kmret" placeholder="" autocomplete="off" v-model="kmret" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="motif">Motif du déplacement :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="motif" placeholder="" autocomplete="off" v-model="motif" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <label for="iti">Itinéraire détaillé (départ, arrivée) : </label>
                    <div class="form-control-container">
                      <textarea class="form-control stretchy" id="iti" placeholder="" v-model="iti"></textarea>
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                </div>

                <div class="form-error mt-3" v-if="status == 'error_update'">
                  <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                  <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                    <li>{{ err.message }}</li>
                  </ul>
                </div>
                <div class="row justify-content-end mt-3">
                  <div class="col-md-2 col-sm-6 mb-3">
                    <button type="button" class="btn btn-block btn-secondary" @click="retour()">RETOUR</button>
                  </div>

                  <div class="col-md-2 col-sm-6 mb-3">
                    <button class="btn btn-success btn-block float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                      <span v-if="status == 'loading'">Connexion en cours</span>
                      <span v-else>VALIDER</span>
                    </button>
                  </div>
                  <div class="col-md-2 col-sm-12 mb-3">
                    <button type="button" class="btn btn-block btn-danger" @click="deleteLogs(update.idLog)">SUPPRIMER</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Update",
  props: ["logs", "mois", "annee"],
  data() {
    return {
      valuedatedep: null,
      valueheuredep: null,
      valuedateret: null,
      valueheureret: null,
      valuekmdep: null,
      valuekmret: null,
      valueiti: null,
      valuemotif: null,
      update: null,
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
      choixHeure: [
        "00h00",
        "00h15",
        "00h30",
        "00h45",
        "01h00",
        "01h15",
        "01h30",
        "01h45",
        "02h00",
        "02h15",
        "02h30",
        "02h45",
        "02h45",
        "03h00",
        "03h15",
        "03h30",
        "03h45",
        "04h00",
        "04h15",
        "04h30",
        "04h45",
        "05h00",
        "05h15",
        "05h30",
        "05h45",
        "06h00",
        "06h15",
        "06h30",
        "06h45",
        "07h00",
        "07h15",
        "07h30",
        "07h45",
        "08h00",
        "08h15",
        "08h30",
        "08h45",
        "09h00",
        "09h15",
        "09h30",
        "09h45",
        "10h00",
        "10h15",
        "10h30",
        "10h45",
        "11h00",
        "11h15",
        "11h30",
        "11h45",
        "12h00",
        "12h15",
        "12h30",
        "12h45",
        "13h00",
        "13h15",
        "13h30",
        "13h45",
        "14h00",
        "14h15",
        "14h30",
        "14h45",
        "15h00",
        "15h15",
        "15h30",
        "15h45",
        "16h00",
        "16h15",
        "16h30",
        "16h45",
        "17h00",
        "17h15",
        "17h30",
        "17h45",
        "18h00",
        "18h15",
        "18h30",
        "18h45",
        "19h00",
        "19h15",
        "19h30",
        "19h45",
        "20h00",
        "20h15",
        "20h30",
        "20h45",
        "21h00",
        "21h15",
        "21h30",
        "21h45",
        "22h00",
        "22h15",
        "22h30",
        "22h45",
        "23h00",
        "23h15",
        "23h30",
        "23h45",
      ],
    };
  },
  created() {
    this.update = JSON.parse(this.logs);
  },
  beforeMount() {
    this.$store.dispatch("getOneVehicules", this.update.id_vehicle);
    this.$store.commit("title", {
      desktop: "Véhicule : " + this.vehicule.registration,
      mobile: this.vehicule.registration,
    });
  },
  computed: {
    validatedFields() {
      return this.datedep != "" && this.heuredep != "" && this.dateret != "" && this.heureret != "" && this.kmdep != "" && this.kmret != "" && this.iti != "" && this.motif != "" ? true : false;
    },
    ...mapState(["status", "errMessage", "user", "vehicule"]),
    datedep: {
      get() {
        return this.valuedatedep === null ? this.update.startDate : this.valuedatedep;
      },
      set(value) {
        this.valuedatedep = value;
      },
    },
    heuredep: {
      get() {
        return this.valueheuredep === null ? this.update.startTime : this.valueheuredep;
      },
      set(value) {
        this.valueheuredep = value;
      },
    },
    dateret: {
      get() {
        return this.valuedateret === null ? this.update.endDate : this.valuedateret;
      },
      set(value) {
        this.valuedateret = value;
      },
    },
    heureret: {
      get() {
        return this.valueheureret === null ? this.update.endTime : this.valueheureret;
      },
      set(value) {
        this.valueheureret = value;
      },
    },
    kmdep: {
      get() {
        return this.valuekmdep === null ? this.update.startKm : this.valuekmdep;
      },
      set(value) {
        this.valuekmdep = value;
      },
    },
    kmret: {
      get() {
        return this.valuekmret === null ? this.update.endKm : this.valuekmret;
      },
      set(value) {
        this.valuekmret = value;
      },
    },
    iti: {
      get() {
        return this.valueiti === null ? this.update.itinerary : this.valueiti;
      },
      set(value) {
        this.valueiti = value;
      },
    },
    motif: {
      get() {
        return this.valuemotif === null ? this.update.reason : this.valuemotif;
      },
      set(value) {
        this.valuemotif = value;
      },
    },
  },
  methods: {
    updateJs() {
      if (this.valuedatedep === null) {
        this.valuedatedep = this.update.startDate;
      }
      if (this.valuedateret === null) {
        this.valuedateret = this.update.endDate;
      }
      if (this.valueheuredep === null) {
        this.valueheuredep = this.update.startTime;
      }
      if (this.valueheureret === null) {
        this.valueheureret = this.update.endTime;
      }
      if (this.valuekmdep === null) {
        this.valuekmdep = this.update.startKm;
      }
      if (this.valuekmret === null) {
        this.valuekmret = this.update.endKm;
      }
      if (this.valueiti === null) {
        this.valueiti = this.update.itinerary;
      }
      if (this.valuemotif === null) {
        this.valuemotif = this.update.reason;
      }
      const self = this;
      this.$store
        .dispatch("updateLogs", {
          startDate: this.valuedatedep.split("/").reverse().join("-"),
          endDate: this.valuedateret.split("/").reverse().join("-"),
          endTime: this.valueheureret.split("h").join(":"),
          startTime: this.valueheuredep.split("h").join(":"),
          startKm: this.valuekmdep,
          endKm: this.valuekmret,
          itinerary: this.valueiti,
          reason: this.valuemotif,
          cp: this.update.usercp,
          id_vehicle: this.update.id_vehicle,
          userId: this.user.userId,
          idLog: this.update.idLog,
        })
        .then(function () {
          jQuery("#myModal").modal("hide");
          self.showAlert({
            icon: "success",
            title: "Modification réussit.",
          });
          self.$router.push({ name: "Journal", params: { vehiculeId: self.update.id_vehicle, retourMois: self.mois, retourAnnee: self.annee } });
        });
    },
    retour() {
      this.$router.push({ name: "Journal", params: { vehiculeId: this.update.id_vehicle, retourMois: this.mois, retourAnnee: this.annee } });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
        //footer: param.footer,
      });
    },
    deleteLogs(id) {
      const self = this;
      this.$store.dispatch("deleteLogs", id).then(function () {
        self.showAlert({
          icon: "success",
          title: "Vous avez supprimée ce trajet avec succès.",
        });
        self.$router.push({ name: "Journal", params: { vehiculeId: self.update.id_vehicle, retourMois: self.mois, retourAnnee: self.annee } });
      });
    },
  },
};
</script>

<style></style>
