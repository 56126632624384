Skip to content Search or jump to… Pull requests Issues Marketplace Explore @Dutdut77 vue-leaflet / vue3-demo-project Public Code Issues Pull requests Actions Projects Wiki Security Insights vue3-demo-project/src/App.vue @DonNicoJs DonNicoJs chore: upgrade demo with new minor features Latest commit 67f7eb5 on 31 Oct 2020 History 1 contributor 129 lines (125 sloc) 2.64 KB

<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <h5 class="card-header bg-white rounded-top text-primary text-uppercase">Recherche d'un accès : </h5>
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-5 mb-3">
                  <label for="ligne" class="font-weight-medium mb-2">Choix de la ligne</label>
                  <select id="ligne" v-model="ligne" class="form-control" @change="changeTable()">
                    <option value="" disabled hidden>Choisissez votre ligne</option>
                    <option v-for="(result, index) in allLigne" :key="index" :value="result" :data="result">Ligne : {{ result }}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-12 mb-3" v-if="ligne">
                <div class="table-wrapper" data-component="table" style="height: 400px; overflow: scroll">
                  <div class="table-scroller dragscroll">
                    <table class="table table-hover">
                      <caption class="sr-only"> Liste des accès </caption>
                      <thead>
                        <tr>
                          <th scope="col"><div class="cell-inner justify-content-center">Ligne</div></th>
                          <th scope="col"><div class="cell-inner justify-content-center">Pk</div></th>
                          <th scope="col"><div class="cell-inner justify-content-center">Accès</div></th>
                          <th scope="col"><div class="cell-inner justify-content-center">WAZE</div></th>
                          <th scope="col"><div class="cell-inner justify-content-center">GOOGLE</div></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(liste, index) in listeAcces" :key="index">
                          <th scope="row">
                            <div class="cell-inner justify-content-center">{{ liste.ligne }} </div>
                          </th>
                          <td>
                            <div class="cell-inner justify-content-center">{{ liste.km }}</div>
                          </td>
                          <td>
                            <div class="cell-inner justify-content-center">{{ liste.acces }}</div>
                          </td>
                          <td>
                            <div class="cell-inner justify-content-center"
                              ><a :href="liste.waze"><img alt="About" src="@/assets/img/waze.png" width="47" height="49" class="mx-2" /></a
                            ></div>
                          </td>
                          <td>
                            <div class="cell-inner justify-content-center"
                              ><a :href="liste.google"><img alt="About" src="@/assets/img/maps.png" width="40" height="40" class="mx-2" /></a
                            ></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-4">
          <div class="mx-auto mt-4" style="height: 500px">
            <l-map :zoom="zoom" :center="center">
              <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
              <!-- <l-control-layers /> -->

              <div v-for="marker in allAcces" :key="marker.id">
                <l-marker :lat-lng="[marker.lat, marker.lng]">
                  <l-popup>
                    <b>Ligne : {{ marker.ligne }} / Pk : {{ marker.km }}</b
                    ><br />
                    Accès : {{ marker.acces }} / Coté : {{ marker.cote }}<br />
                    {{ marker.adresse }}<br />
                    {{ marker.ville }}<br />
                    <center>
                      <a :href="marker.waze"><img alt="About" src="@/assets/img/waze.png" width="47" height="49" class="mx-2 pt-1" /></a>
                      <a :href="marker.google"><img alt="About" src="@/assets/img/maps.png" width="40" height="40" class="mx-2" /></a>
                    </center>
                  </l-popup>
                  <!-- <l-icon :icon-url="marker.imageUrl" :icon-size="iconSize" /> -->
                </l-marker>
              </div>
              <!-- <l-marker :lat-lng="[location.coords.latitude, location.coords.longitude]">
                <l-tooltip> Je suis Ici ! </l-tooltip>
                <l-icon ref="icon">
                  <img src="@/assets/img/profil.svg" class="float-left my-2" alt="Moi" width="50" />
                </l-icon>
              </l-marker> -->
            </l-map>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import $ from "jquery";
import { LMap, LTileLayer, LMarker, LTooltip, LPopup, LIcon } from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    LIcon,
  },
  data() {
    return {
      // location: null,
      // gettingLocation: false,
      // errorStr: null,

      ligne: "",
      listeAcces: [],
      zoom: 10,
      center: [48.806859, 3.083659],
    };
  },
  // created() {
  //   //do we support geolocation
  //   if (!("geolocation" in navigator)) {
  //     this.errorStr = "Geolocation is not available.";
  //     return;
  //   }

  //   this.gettingLocation = true;
  //   // get position
  //   navigator.geolocation.getCurrentPosition(
  //     (pos) => {
  //       this.gettingLocation = false;
  //       this.location = pos;
  //     },
  //     (err) => {
  //       this.gettingLocation = false;
  //       this.errorStr = err.message;
  //     }
  //   );
  // },
  beforeMount() {
    this.$store.commit("title", {
      desktop: "Accès Paris Est",
      mobile: "Accès",
    });
    this.$store.dispatch("getAllAcces");
  },
  computed: {
    ...mapState(["allAcces"]),
    // center() {
    //   const coord = [this.location.coords.latitude, this.location.coords.longitude];
    //   return coord;
    // },
    allLigne() {
      let result = [];
      for (let i = 0; i < this.allAcces.length; i++) {
        if (result.includes(this.allAcces[i].ligne)) {
        } else {
          result.push(this.allAcces[i].ligne);
        }
      }
      return result;
    },
  },
  methods: {
    changeTable() {
      this.listeAcces = [];
      for (let i = 0; i < this.allAcces.length; i++) {
        if (this.allAcces[i].ligne == this.ligne) {
          this.listeAcces.push(this.allAcces[i]);
        }
      }
    },
  },
};
</script>
<style>
.restaurant-icon {
  height: 50px;
  width: auto;
}
</style>
