<template>
  <section>
    Home
    <br />
    Date Début : {{ startDate }} Date Fin : {{ endDate }}
    <br />
    Diff : {{ diff }}
  </section>
</template>

<script>
export default {
  name: "Home",
  beforeMount() {
    this.$router.push({ name: "Journal" });
  },

  computed: {},
};
</script>

<style></style>
