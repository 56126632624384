<template>
  <section class="full">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-4 col-sm-8 mx-auto my-auto">
          <div class="card">
            <h5 class="card-header bg-primary rounded-top text-white">CONNEXION JOURNAL DE SERVICE</h5>
            <div class="card-body d-flex flex-column">
              <img src="../assets/img/secure.svg" class="secure img-fluid mx-auto mb-2 w-50" />
              <form @submit.prevent="login()">
                <div class="form-row">
                  <div class="col-12 mb-3">
                    <label class="font-weight-medium mb-2 required" for="numerocp">Identifiant</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="numerocp" placeholder="Numéro CP" autocomplete="off" v-model="numerocp" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-12">
                    <label class="font-weight-medium mb-2 required" for="password">Mot de passe </label>
                    <div class="form-control-container">
                      <input type="password" class="form-control" id="password" placeholder="" autocomplete="off" v-model="password" aria-describedby="invalidPassword" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                </div>

                <div class="form-error mt-3" v-if="status == 'error_login'">
                  <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                  <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                    <li>{{ err.message }}</li>
                  </ul>
                </div>
                <button class="btn btn-primary mt-3 float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>VALIDER</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      numerocp: "",
      password: "",
    };
  },
  mounted() {
    this.$store.commit("title", {
      desktop: "Login - Journal de service",
      mobile: "Login",
    });
  },
  computed: {
    validatedFields() {
      return this.numerocp != "" && this.password != "" ? true : false;
    },
    ...mapState(["status", "errMessage", "user"]),
  },
  methods: {
    login() {
      const self = this;
      this.$store
        .dispatch("login", {
          cp: this.numerocp,
          password: this.password,
        })
        .then(function () {
          if (self.$route.query.redirect) {
            self.$router.push(self.$route.query.redirect);
          } else {
            self.$router.push("/js/journal");
          }
          //self.$router.push("/");
        });
    },
  },
};
</script>

<style scoped lang="scss">
.full {
  height: calc(100vh - 120px);
  display: flex;
  padding: 20px 0;
  @media (min-width: 768px) {
    padding: 0;
    align-items: center;
  }
}
.auth {
  width: 70%;
}
.secure {
  width: 200px;

  @media (min-width: 768px) {
    width: 300px;
  }
}
</style>
