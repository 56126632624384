<template>
  <section>
    <div class="row h-100 mt-5">
      <div class="col-6 my-auto">
        <h1 class="text-primary text-center text-uppercase">Page non trouvée.<br />Erreur 404</h1>
      </div>
      <div class="col-6">
        <img src="../assets/img/notfound.svg" class="img-fluid w-75 mx-auto d-block" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Lock",
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("title", {
      desktop: "Page non trouvée",
      mobile: "Page non Trouvée",
    });
  },
};
</script>

<style scoped lang="scss"></style>
