<template>
  <section class="print">
    <div class="container-fluid pt-3">
      <div class="d-none d-print-block">
        <div class="row bg-gray d-flex align-items-center py-4">
          <div class="col-3"></div>
          <div class="col-6">
            <h1 class="titre_print text-uppercase text-white text-center d-none d-md-block mb-0 mt-1">
              {{ profil.site }} <br />{{ profil.nameUnit }}<br />
              {{ lettreMois }} {{ annee }}
            </h1>
          </div>
          <div class="col-3">
            <img src="@/assets/img/sncf-logo.png" class="float-right my-2 mx-4" alt="Logo SNCF" width="140" />
          </div>
        </div>
      </div>

      <div class="row d-print-none">
        <div class="col-md-12">
          <div class="card">
            <h1 class="card-header bg-white text-primary text-uppercase mt-2">{{ profil.site }} - {{ profil.nameUnit }}</h1>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-4 mb-1">
                  <label for="mois" class="font-weight-medium mb-2">Mois</label>
                  <select id="mois" v-model="mois" class="form-control" @change="changeSelect()">
                    <option value="1">Janvier</option>
                    <option value="2">Février</option>
                    <option value="3">Mars</option>
                    <option value="4">Avril</option>
                    <option value="5">Mai</option>
                    <option value="6">Juin</option>
                    <option value="7">Juillet</option>
                    <option value="8">Aout</option>
                    <option value="9">Septembre</option>
                    <option value="10">Octobre</option>
                    <option value="11">Novembre</option>
                    <option value="12">Décembre</option>
                  </select>
                </div>
                <div class="col-md-4 mb-1">
                  <label for="annee" class="font-weight-medium mb-2">Année</label>
                  <select id="annee" v-model="annee" class="form-control" @change="changeSelect()">
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>
                <div class="col-md-4 mb-1" v-if="user.role == 1">
                  <label for="secteur" class="font-weight-medium mb-2">Choix du secteur</label>
                  <select id="secteur" v-model="unite" class="form-control" @change="changeSelect()">
                    <option value="" disabled hidden>Choisissez votre unité</option>
                    <option v-for="(unit, index) in allUnits" :key="index" :value="unit.idUnit" :data="unit.idUnit">{{ unit.nameUnit }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Validation</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ pourcentage }} % </h1>
                <footer class="blockquote-footer">{{ totalValidation }} véhicules sur {{ totalCar }} </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Distance</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalKm }} Km</h1>
                <footer class="blockquote-footer">Kilomètre total </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Carburant</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalLitre }} Litres</h1>
                <footer class="blockquote-footer">Nb de litres </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Péage</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalPeage }} €</h1>
                <footer class="blockquote-footer">Cout péage </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Lavage</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalLavage }} €</h1>
                <footer class="blockquote-footer">Cout lavage </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Autre</h5>
            <div class="card-body">
              <blockquote class="blockquote text-center my-auto py-5">
                <h1 class="mb-0 font-weight-bold text-pink">{{ totalAutre }} €</h1>
                <footer class="blockquote-footer">Cout autre </footer>
              </blockquote>
            </div>
            <div class="">
              <img alt="About" src="@/assets/img/waves.svg" class="bg-pink img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div class="row d-print-none">
        <div class="col-md-12 mt-4">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Liste des véhicules</h5>
            <div class="card-body">
              <TableEtablissement :dashboard="dashboard" :mois="mois" :annee="annee" :lettreMois="lettreMois" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import VueApexCharts from "vue3-apexcharts";
import TableEtablissement from "@/components/Tables/Table_Etablissement.vue";

export default {
  name: "Unite",
  components: { VueApexCharts, TableEtablissement },
  data() {
    return {
      valueMois: null,
      valueAnnee: null,
      valueUnite: null,
      result: 0,
    };
  },
  mounted() {
    this.$store.dispatch("getProfil");
    this.$store.dispatch("getAllUnits");
    this.$store.dispatch("getDashboardUnite", { userId: this.user.userId, month: this.mois, year: this.annee, site: this.user.site, unite: this.user.unit });
  },
  updated() {
    this.$store.commit("title", {
      desktop: this.profil.nameUnit + " - " + this.lettreMois + " " + this.annee,
      mobile: this.profil.nameUnit,
    });
  },
  computed: {
    ...mapState(["dashboard", "user", "profil", "allUnits"]),
    mois: {
      get() {
        return this.valueMois === null ? new Date().getMonth() + 1 : this.valueMois;
      },
      set(value) {
        this.valueMois = value;
      },
    },
    annee: {
      get() {
        return this.valueAnnee === null ? new Date().getFullYear() : this.valueAnnee;
      },
      set(value) {
        this.valueAnnee = value;
      },
    },
    unite: {
      get() {
        return this.valueUnite === null ? this.user.unit : this.valueUnite;
      },
      set(value) {
        this.valueUnite = value;
      },
    },

    lettreMois() {
      if (this.mois == "1") {
        return "Janvier";
      }
      if (this.mois == "2") {
        return "Février";
      }
      if (this.mois == "3") {
        return "Mars";
      }
      if (this.mois == "4") {
        return "Avril";
      }
      if (this.mois == "5") {
        return "Mai";
      }
      if (this.mois == "6") {
        return "Juin";
      }
      if (this.mois == "7") {
        return "Juillet";
      }
      if (this.mois == "8") {
        return "Aout";
      }
      if (this.mois == "9") {
        return "Septembre";
      }
      if (this.mois == "10") {
        return "Octobre";
      }
      if (this.mois == "11") {
        return "Novembre";
      }
      if (this.mois == "12") {
        return "Décembre";
      }
    },
    totalCar() {
      return this.dashboard.length;
    },
    pourcentage() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        if (this.dashboard[i].validation > 0) {
          result += 1;
        }
      }

      const res = (result * 100) / this.totalCar;
      const pourcent = Math.round(res * 100) / 100;
      return pourcent;
    },
    totalValidation() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        if (this.dashboard[i].validation > 0) {
          result += 1;
        }
      }
      return Math.round(result * 100) / 100;
    },
    totalKm() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].totalMois;
      }
      return result;
    },
    totalLitre() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].litres;
      }
      return Math.round(result * 100) / 100;
    },
    totalPeage() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].peage;
        console.log(this.dashboard[i].peage);
      }
      return Math.round(result * 100) / 100;
    },
    totalLavage() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].lavage;
      }
      return Math.round(result * 100) / 100;
    },
    totalAutre() {
      let result = 0;
      for (let i = 0; i < this.dashboard.length; i++) {
        result += this.dashboard[i].autre;
      }
      return Math.round(result * 100) / 100;
    },
    listeIdUp() {
      let result = [];
      for (let i = 0; i < this.dashboard.length; i++) {
        if (result.includes(this.dashboard[i].idUnit)) {
        } else {
          result.push(this.dashboard[i].idUnit);
        }
      }
      return result;
    },
    listeUp() {
      let result = [];
      let name = "";
      for (let i = 0; i < this.listeIdUp.length; i++) {
        for (let j = 0; j < this.dashboard.length; j++) {
          if (this.dashboard[j].idUnit == this.listeIdUp[i]) {
            name = this.dashboard[j].nameUnit;
          }
        }
        result.push(name);
      }
      return result;
    },
  },
  methods: {
    changeSelect() {
      this.$store.dispatch("getDashboardUnite", { userId: this.user.userId, month: this.mois, year: this.annee, site: this.user.site, unite: this.unite });
    },
  },
};
</script>

<style scoped lang="scss">
.titre_print {
  font-size: 2.5rem;
}
</style>
