<template>
  <div>
    <table id="tableUser" class="table hover" style="width: 100%">
      <thead>
        <tr>
          <th>CP</th>
          <th>Nom</th>
          <th>Prénom</th>
          <th>Unité</th>
          <th>Niveau</th>
          <th><b-icon-three-dots /></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(agent, index) in allAgents" :key="index">
          <td>{{ agent.cp }}</td>
          <td>{{ agent.nom }}</td>
          <td>{{ agent.prenom }}</td>
          <td>{{ agent.nameUnit }}</td>
          <td class="text-center">
            <span class="badge badge-success" v-if="agent.id_role == 3">A</span>
            <span class="badge badge-warning" v-if="agent.id_role == 2">U</span>
            <span class="badge badge-danger" v-if="agent.id_role == 1">E</span>
          </td>
          <td>
            <button type="button" class="btn btn-only-icon btn-secondary" @click="Modal(agent)">
              <b-icon-pencil-square />
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal  -->
    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="display-1">{{ agent.nom }} {{ agent.prenom }} ({{ agent.cp }})</h1>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="col mb-3" v-if="user.role == 1">
                  <label for="secteur" class="font-weight-medium mb-2 required">Choix du secteur</label>
                  <select id="secteur" v-model="unite" class="form-control">
                    <option v-for="(unit, index) in allUnits" :key="index" :value="unit.idUnit"> {{ unit.nameUnit }} </option>
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="numerocp">Numéro de CP</label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="numerocp" placeholder="Numéro de CP" autocomplete="off" v-model="numerocp" />
                    <span class="form-control-state"></span>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="nom">Nom </label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="nom" placeholder="Nom" autocomplete="off" v-model="nom" aria-describedby="invalidnom" />
                    <span class="form-control-state"></span>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="prenom">Prénom </label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="prenom" placeholder="Prénom" autocomplete="off" v-model="prenom" aria-describedby="invalidprenom" />
                    <span class="form-control-state"></span>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6 mb-3">
                  <label class="font-weight-medium mb-2 required" for="email">Email </label>
                  <div class="form-control-container">
                    <input type="text" class="form-control" id="email" placeholder="prénom.nom@reseau.sncf.fr" autocomplete="off" v-model="email" aria-describedby="invalidemail" />
                    <span class="form-control-state"></span>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-12 mb-3">
                  <label class="font-weight-medium mb-2 required" for="email">Habilitation</label>
                  <div class="form-control-container">
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline1" name="customRadioInline1" class="custom-control-input" v-model="niveau" value="3" />
                      <label class="custom-control-label font-weight-medium" for="customRadioInline1">Agent</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioInline2" name="customRadioInline1" class="custom-control-input" v-model="niveau" value="2" />
                      <label class="custom-control-label font-weight-medium" for="customRadioInline2">Unité</label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline" v-if="user.role == 1">
                      <input type="radio" id="customRadioInline3" name="customRadioInline1" class="custom-control-input" v-model="niveau" value="1" />
                      <label class="custom-control-label font-weight-medium" for="customRadioInline3">Etablissemnt</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-error mt-3" v-if="status == 'error_update'">
                <h2 class="text-white text-uppercase"> Oouups ! Vous avez une erreur </h2>
                <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                  <li>{{ err.message }}</li>
                </ul>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-12 col-sm-6 mb-3">
                <button :disabled="!validatedFields" class="btn btn-block btn-primary" @click="updateAgent()">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>Modifier</span>
                </button>
              </div>
              <div class="col-12 col-sm-6 mb-3">
                <button type="button" class="btn btn-block btn-danger" @click="deleteAgent(this.agent.idUser)">Supprimer</button>
              </div>
              <div class="col-sm-12 mb-3">
                <button type="button" class="btn btn-block btn-secondary" @click="close()">Fermer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "Table_Users",
  data() {
    return {
      agent: {},
      valueNom: null,
      valueUnite: null,
      valuePrenom: null,
      valueEmail: null,
      valueNumerocp: null,
      valueNiveau: null,
    };
  },
  beforeUpdate() {
    this.destroyTable();
  },
  updated() {
    this.getTableUser();
  },
  created() {
    this.$store.dispatch("getAllAgents", { userId: this.user.userId, id_unit: this.user.unit, id_role: this.user.role, id_site: this.user.site });
  },

  methods: {
    Modal(agent) {
      this.agent = agent;
      jQuery("#myModal").modal({
        keyboard: true,
        toggle: true,
      });
    },
    close() {
      this.$store.commit("setStatus", "");
      this.valueNom = null;
      this.valueUnite = null;
      this.valuePrenom = null;
      this.valueEmail = null;
      this.valueNumerocp = null;
      this.valueNiveau = null;
      jQuery("#myModal").modal("hide");
    },
    updateAgent() {
      if (this.valueNom === null) {
        this.valueNom = this.agent.nom;
      }
      if (this.valuePrenom === null) {
        this.valuePrenom = this.agent.prenom;
      }
      if (this.valueNumerocp === null) {
        this.valueNumerocp = this.agent.cp;
      }
      if (this.valueEmail === null) {
        this.valueEmail = this.agent.email;
      }
      if (this.valueUnite === null) {
        this.valueUnite = this.agent.id_unit;
      }
      if (this.valueNiveau === null) {
        this.valueNiveau = this.agent.id_role;
      }
      const self = this;
      this.$store
        .dispatch("updateAgent", {
          nom: this.valueNom.toUpperCase(),
          prenom: this.valuePrenom,
          cp: this.valueNumerocp.toUpperCase(),
          email: this.valueEmail,
          id_unit: this.valueUnite,
          id_site: this.user.site,
          id_role: this.valueNiveau,
          agentId: this.agent.idUser,
          userId: this.user.userId,
        })
        .then(function () {
          self.destroyTable();
          self.close();
          self.showAlert({
            icon: "success",
            title: "Modification réalisée avec succès.",
            footer: self.agent.society + "  -  " + self.agent.site,
          });
        });
    },
    deleteAgent(agentId) {
      const self = this;
      this.$store.dispatch("deleteUser", agentId).then(function () {
        jQuery("#myModal").modal("hide");
        self.showAlert({
          icon: "success",
          title: self.agent.nom + " " + self.agent.prenom + " a été supprimé avec succès.",
          footer: self.agent.society + "  -  " + self.agent.site,
        });
      });
    },
    destroyTable() {
      $("#tableUser").DataTable().destroy();
    },
    getTableUser() {
      $("#tableUser").DataTable({
        language: {
          emptyTable: "No data available in table",
          search: "Recherche ",
          paginate: {
            first: "Premier",
            last: "Dernier",
            next: "Suivant",
            previous: "Précédent",
          },
          lengthMenu: "Voir _MENU_ membres",
        },
        retrieve: true,
        searching: true,
        info: false,
        scrollY: "400px",
        scrollX: true,
        scrollCollapse: true,
        paging: false,
      });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
        //footer: param.footer,
      });
    },
  },
  computed: {
    ...mapState(["allAgents", "allUnits", "status", "errMessage", "user"]),
    validatedFields() {
      if (this.user.role == 1) {
        return this.numerocp != "" && this.nom != "" && this.prenom != "" && this.email != "" && this.unite != "" ? true : false;
      } else {
        return this.numerocp != "" && this.nom != "" && this.prenom != "" && this.email != "" ? true : false;
      }
    },
    checked() {
      if (this.user.role == 1) {
        return this.numerocp != "" && this.nom != "" && this.prenom != "" && this.email != "" && this.unite != "" ? true : false;
      } else {
        return this.numerocp != "" && this.nom != "" && this.prenom != "" && this.email != "" ? true : false;
      }
    },
    nom: {
      get() {
        return this.valueNom === null ? this.agent.nom : this.valueNom;
      },
      set(value) {
        this.valueNom = value;
      },
    },
    prenom: {
      get() {
        return this.valuePrenom === null ? this.agent.prenom : this.valuePrenom;
      },
      set(value) {
        this.valuePrenom = value;
      },
    },
    email: {
      get() {
        return this.valueEmail === null ? this.agent.email : this.valueEmail;
      },
      set(value) {
        this.valueEmail = value;
      },
    },
    numerocp: {
      get() {
        return this.valueNumerocp === null ? this.agent.cp : this.valueNumerocp;
      },
      set(value) {
        this.valueNumerocp = value;
      },
    },
    niveau: {
      get() {
        return this.valueNiveau === null ? this.agent.id_role : this.valueNiveau;
      },
      set(value) {
        this.valueNiveau = value;
      },
    },
    unite: {
      get() {
        return this.valueUnite === null ? this.agent.id_unit : this.valueUnite;
      },
      set(value) {
        this.valueUnite = value;
      },
    },
  },
};
</script>

<style></style>
