import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/bootstrap-sncf.min.css"
import "../src/assets/js/bootstrap-sncf.min.js"
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import "sweetalert2/dist/sweetalert2.min.css";
import VCalendar from 'v-calendar';
import Swal from "sweetalert2";
window.Swal = Swal;




import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import "datatables.net-dt";
import "datatables.net-responsive-dt";
import "datatables.net-scroller-dt";

import "./assets/css/datatable.scss";

library.add(fas, fab);

createApp(App)
  .use(store)
  .use(router)
  .use(BootstrapIconsPlugin)
  .use(VCalendar, {})
  .component("fa", FontAwesomeIcon)
  .mount("#app");
