<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">{{ profil.site }} - {{ profil.nameUnit }}</h5>
            <div class="card-body">
              <h5 class="card-title mb-3">Ajouter un véhicule :</h5>

              <form @submit.prevent="addVehicules()">
                <div class="form-row">
                  <div class="col-md-4 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="numerocp">Immatriculation</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="numerocp" placeholder="Immatriculation" autocomplete="off" v-model="immatriculation" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-md-4 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="nom">Constructeur</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="nom" placeholder="Contructeur" autocomplete="off" v-model="constructeur" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="prenom">Model </label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="prenom" placeholder="Model" autocomplete="off" v-model="model" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 mb-3">
                    <label for="secteur" class="font-weight-medium mb-2 required">Carte Essence</label>
                    <select id="secteur" v-model="essence" class="form-control">
                      <option value="" disabled hidden>Choisissez votre carte</option>
                      <option v-for="(card, index) in allCards" :key="index" :value="card.idCard" :data="card.idCard">{{ card.cardName }}</option>
                    </select>
                  </div>
                  <div class="col-md-6 col-sm-6 mb-3">
                    <label for="date" class="font-weight-medium mb-2 required">Date de mise à disposition</label>
                    <v-date-picker locale="fr" v-model="dateMad" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>
                </div>

                <div class="form-error mt-3" v-if="status == 'error_vehicules'">
                  <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                  <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                    <li>{{ err.message }}</li>
                  </ul>
                </div>

                <button class="btn btn-primary mt-3 float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>VALIDER</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Liste des véhicules en service</h5>
            <div class="card-body">
              <TableVehicules />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import TableVehicules from "@/components/Tables/Table_Vehicules.vue";
import $ from "jquery";

export default {
  name: "Véhicules",
  components: { TableVehicules },
  data() {
    return {
      immatriculation: "",
      constructeur: "",
      model: "",
      essence: "",
      dateMad: "",
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
    };
  },
  mounted() {
    this.$store.commit("title", {
      desktop: "Gestion des véhicules",
      mobile: "Véhicules",
    });
    this.$store.dispatch("getProfil");
    this.$store.dispatch("getAllCards");
  },

  methods: {
    addVehicules() {
      const date_acqui = this.dateMad.split("/").reverse().join("-");
      const self = this;
      this.$store
        .dispatch("addVehicules", {
          registration: this.immatriculation.toUpperCase(),
          carMaker: this.constructeur.toUpperCase(),
          carModel: this.model.toUpperCase(),
          status: 1,
          id_unit: this.user.unit,
          id_site: this.user.site,
          id_card: this.essence,
          date_acqui: date_acqui,
          userId: this.user.userId,
          date_resti: "0000-00-00",
        })
        .then(function () {
          $("#tableVehicules").DataTable().destroy();
          self.showAlert({
            icon: "success",
            title: self.immatriculation.toUpperCase() + " a été ajouté avec succès.",
          });
          self.immatriculation = "";
          self.constructeur = "";
          self.model = "";
          self.essence = "";
          self.dateMad = "";
        });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
        // footer: param.footer,
      });
    },
  },
  computed: {
    validatedFields() {
      return this.immatriculation != "" && this.constructeur != "" && this.model != "" && this.essence != "" && this.dateMad != "" ? true : false;
    },

    ...mapState(["status", "errMessage", "profil", "user", "allCards"]),
  },
};
</script>

<style></style>
