<template>
  <header class="d-print-none">
    <div class="nav bg-gray d-flex align-items-center px-1">
      <div class="col-3">
        <img src="../assets/img/sncf-logo.png" class="float-left my-2" alt="Logo SNCF" width="70" />
      </div>
      <div class="col-6">
        <h1 class="text-uppercase text-white text-center d-none d-md-block mb-0 mt-1">
          {{ title.desktop }}
        </h1>

        <h1 class="text-uppercase text-white text-center d-md-none mb-0 mt-1">
          {{ title.mobile }}
        </h1>
      </div>
      <div class="col-3">
        <div class="hamburger d-md-none float-right" id="hamburger" @click="ShowMenu()" v-if="user.userId > 0">
          <span class="bar"></span>
          <span class="bar"></span>
          <span class="bar"></span>
        </div>
      </div>
    </div>

    <div class="subnav bg-primary" id="subnav" v-if="user.userId > 0">
      <nav>
        <ul>
          <!-- <li><router-link to="/" @click="HideMenu()">Accueil</router-link></li> -->
          <li v-if="user.role < 3">
            <a href="javascript:void(0);" @click="ShowDropdown('dashboard')">Dashboard</a>
            <ul id="dashboard">
              <li v-if="user.role < 2"><router-link to="/dashboard/etablissement" @click="HideMenu()">Etablissement</router-link></li>
              <li><router-link to="/dashboard/unite" @click="HideMenu()">Unité</router-link></li>
            </ul>
          </li>
          <li>
            <router-link to="/js/journal" @click="HideMenu()">Journal de Service</router-link>
          </li>
          <li>
            <router-link to="/acces" @click="HideMenu()">Accès</router-link>
          </li>
          <li v-if="user.role < 3">
            <a href="javascript:void(0);" @click="ShowDropdown('parametres')">Paramètres</a>
            <ul id="parametres">
              <li><router-link to="/parametres/agents" @click="HideMenu()">Agents</router-link></li>
              <li><router-link to="/parametres/vehicules" @click="HideMenu()">Véhicules</router-link></li>
              <li><router-link to="/parametres/cartes" @click="HideMenu()">Cartes esssence</router-link></li>
            </ul>
          </li>
          <li v-if="user">
            <a href="javascript:void(0);" @click="ShowDropdown('compte')">Mon compte</a>
            <ul id="compte">
              <li><router-link to="/compte/changeMdp" @click="HideMenu()">Mot de passe</router-link></li>
              <li><router-link to="/login" @click="Deconnexion()">Se déconnecter</router-link></li>
            </ul>
          </li>
          <li v-if="!user"><router-link to="/login" @click="HideMenu()">Login</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Navbar",
  components: {},
  methods: {
    ShowDropdown(id) {
      var show = document.getElementsByClassName("showdrop");
      for (var i = 0; i < show.length; i++) {
        if (show[i].id != id) {
          show[i].classList.remove("showdrop");
        }
      }
      document.getElementById(id).classList.toggle("showdrop");
    },
    ShowMenu() {
      document.getElementById("hamburger").classList.toggle("cross");
      document.getElementById("subnav").classList.toggle("change");
    },
    HideMenu() {
      var show = document.getElementsByClassName("showdrop");
      for (var i = 0; i < show.length; i++) {
        show[i].classList.remove("showdrop");
      }
      document.getElementById("hamburger").classList.remove("cross");
      document.getElementById("subnav").classList.remove("change");
    },
    Deconnexion() {
      this.HideMenu();
      localStorage.removeItem("user");
      this.$store.commit("deleteStore");
    },
    //   Auth(num) {
    //     return this.$store.state.user.role.filter(n=>n===num).length===0? false : true;
    //  }
  },
  computed: {
    ...mapState(["user", "title"]),
  },
};
</script>

<style scoped lang="scss">
.btn:active,
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none !important;
}
.nav {
  height: 60px;
}
.hamburger {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  width: 30px;
  height: 100%;
  cursor: pointer;
  z-index: 10;
}
.hamburger .bar {
  z-index: 1010;
  height: 3px;
  width: 100%;
  margin: 2px;
  background-color: var(--white);
  border-radius: 10px;
  transition: 0.3s ease;
}
.cross .bar:nth-child(2) {
  opacity: 0;
}
.cross .bar:first-child {
  transform: rotate(-45deg) translate(-3px, 7px);
}
.cross .bar:last-child {
  transform: rotate(45deg) translate(-3px, -7px);
}
.subnav {
  display: flex;
  visibility: hidden;
  height: 0;

  @media (min-width: 768px) {
    height: 50px;
    width: 100%;
    visibility: visible;
  }
}
.change {
  visibility: visible;
  position: absolute;
  top: 0;
  z-index: 1000;
  height: 100vh;
  width: 100%;
  transition: 0.3s ease;
}
nav {
  margin-top: 80px;
  width: 100%;
  @media (min-width: 768px) {
    margin: 0;
  }
}
nav ul {
  list-style: none;
  width: 100%;
  padding-left: 40px;
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
  }
}
nav ul li {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 768px) {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }
}
nav ul li a {
  color: var(--white);
  line-height: 50px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  @media (min-width: 768px) {
    width: auto;
  }
}
nav ul li a:hover {
  color: var(--gray);
}

nav ul ul {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.1s ease-in-out;
  @media (min-width: 768px) {
    position: absolute;
    z-index: 1000;
    top: 50px !important;
    left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    text-align: left;
    list-style: none;
    background-color: var(--white);
    background-clip: padding-box;
    border: 1px solid var(--light);
    border-radius: 0.4375rem;
  }
}

.showdrop {
  opacity: 1;
  height: auto;
  width: 200px;
}

nav ul ul li a {
  border-left: 1px solid var(--white);
  padding-left: 10px;
  @media (min-width: 768px) {
    color: var(--gray);
    border: none;
    padding-left: 0;
  }
}

nav ul ul li a:hover {
  color: var(--gray);
  @media (min-width: 768px) {
    color: var(--primary);
  }
}
</style>
