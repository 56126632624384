<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-white rounded-top text-primary text-uppercase">Choix du véhicule :</h5>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md-3 mb-3">
                  <label for="secteur" class="font-weight-medium mb-2">Choix du secteur</label>
                  <select id="secteur" v-model="unite" class="form-control" @change="changeUnite()">
                    <option value="" disabled hidden>Choisissez votre unité</option>
                    <option v-for="(unit, index) in allUnits" :key="index" :value="unit.idUnit" :data="unit.idUnit">{{ unit.nameUnit }}</option>
                  </select>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="immat" class="font-weight-medium mb-2 required">Immatriculation</label>
                  <select id="immat" v-model="idVehicule" class="form-control" @change="changeSelect()">
                    <option value="0" disabled hidden>Choisissez votre véhicule</option>
                    <option v-for="(immat, index) in allVehicules" :key="index" :value="immat.idVehicle" :data="immat.idVehicle">{{ immat.registration }} ( {{ immat.carMaker }} {{ immat.carModel }} )</option>
                  </select>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="mois" class="font-weight-medium mb-2">Mois</label>
                  <select id="mois" v-model="mois" class="form-control" @change="changeSelect()">
                    <option value="1">Janvier</option>
                    <option value="2">Février</option>
                    <option value="3">Mars</option>
                    <option value="4">Avril</option>
                    <option value="5">Mai</option>
                    <option value="6">Juin</option>
                    <option value="7">Juillet</option>
                    <option value="8">Aout</option>
                    <option value="9">Septembre</option>
                    <option value="10">Octobre</option>
                    <option value="11">Novembre</option>
                    <option value="12">Décembre</option>
                  </select>
                </div>
                <div class="col-md-3 mb-3">
                  <label for="annee" class="font-weight-medium mb-2">Année</label>
                  <select id="annee" v-model="annee" class="form-control" @change="changeSelect()">
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="vehicule">
        <div class="col-md-7 mt-3">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Description</h5>

            <div class="card-body">
              <div class="col-sm-6">
                <dl class="row">
                  <dt class="col-sm-7">Immatriculation :</dt>
                  <dd class="col-sm-5">{{ vehicule.registration }}</dd>

                  <dt class="col-sm-7">Constructeur :</dt>
                  <dd class="col-sm-5">
                    {{ vehicule.carMaker }}
                  </dd>

                  <dt class="col-sm-7">Modèle :</dt>
                  <dd class="col-sm-5">{{ vehicule.carModel }}</dd>

                  <dt class="col-sm-7 text-truncate">Type :</dt>
                  <dd class="col-sm-5">{{ vehicule.carType }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 mt-3">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Options</h5>

            <div class="card-body">
              <div class="row">
                <div class="col-xl-6 mb-3">
                  <button type="button" class="btn btn-block btn-info" v-if="validation == 0" @click="newJs()">Ajouter un trajet</button>
                </div>
                <div class="col-xl-6 mb-3">
                  <button type="button" class="btn btn-block btn-info" v-if="validation == 0" @click="depense()">Ajouter une dépense</button>
                </div>
                <div class="col-xl-6 mb-3">
                  <button type="button" class="btn btn-block btn-success" @click="ModalQrCode(idVehicule)">Afficher QR Code</button>
                </div>
                <div class="col-xl-6 mb-3">
                  <button type="button" class="btn btn-block btn-warning" @click="pdf()">Export PDF</button>
                </div>
                <div class="col-xl-12">
                  <button type="button" class="btn btn-block btn-danger" v-if="validation == 0" @click="ModalValidation()">Valider (en fin de mois)</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="vehicule">
        <div class="col-12 mt-3">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Trajets - {{ lettreMois }} {{ annee }}</h5>
            <div class="card-body">
              <div class="table-wrapper" data-component="table">
                <div class="table-scroller dragscroll">
                  <table class="table table-hover" v-if="allLogsVehicle.length > 0">
                    <caption class="sr-only"> Liste des trajets </caption>
                    <thead>
                      <tr>
                        <th scope="col"><div class="cell-inner justify-content-center">Départ</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Arrivée</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Itinéraire</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Km début</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Km fin</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Conducteur</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Motif</div></th>
                        <th scope="col" v-if="validation == 0"><div class="cell-inner justify-content-center">#</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(allLogs, index) in allLogsVehicle" :key="index">
                        <th scope="row">
                          <div class="cell-inner justify-content-center">{{ allLogs.startDate }} - {{ allLogs.startTime }}</div>
                        </th>
                        <td>
                          <div class="cell-inner justify-content-center">{{ allLogs.endDate }} - {{ allLogs.endTime }}</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center">{{ allLogs.itinerary }}</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center">{{ allLogs.startKm }}</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center">{{ allLogs.endKm }}</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center">{{ allLogs.nom }}.{{ allLogs.prenom.charAt(0) }}</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center">{{ allLogs.reason }}</div>
                        </td>
                        <td v-if="validation == 0">
                          <div class="cell-inner justify-content-center pointer" v-if="allLogs.usercp == user.cp" @click="update(allLogs)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style="color: cornflowerblue" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div v-else>Aucune donnée</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="vehicule">
        <div class="col-md-12 mt-3 mb-3">
          <div class="card h-100">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Factures - {{ lettreMois }} {{ annee }}</h5>

            <div class="card-body">
              <div class="table-wrapper" data-component="table">
                <div class="table-scroller dragscroll">
                  <table class="table table-hover" v-if="allDepenses.length > 0">
                    <thead>
                      <tr>
                        <th scope="col"><div class="cell-inner justify-content-center">Date</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Carburant</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Péage</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Lavage</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Autre</div></th>
                        <th scope="col"><div class="cell-inner justify-content-center">Photo</div></th>
                        <th scope="col" v-if="validation == 0"><div class="cell-inner justify-content-center">#</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(allExp, index) in allDepenses" :key="index">
                        <th scope="row">
                          <div class="cell-inner justify-content-center">{{ allExp.date }}</div>
                        </th>
                        <td>
                          <div class="cell-inner justify-content-center" v-if="allExp.carburant">{{ allExp.carburant }}</div>
                          <div class="cell-inner justify-content-center" v-else>-</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center" v-if="allExp.peage">{{ allExp.peage }}</div>
                          <div class="cell-inner justify-content-center" v-else>-</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center" v-if="allExp.lavage">{{ allExp.lavage }}</div>
                          <div class="cell-inner justify-content-center" v-else>-</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center" v-if="allExp.autre">{{ allExp.autre }}</div>
                          <div class="cell-inner justify-content-center" v-else>-</div>
                        </td>
                        <td>
                          <div class="cell-inner justify-content-center pointer" v-if="allExp.url" @click="Modal(allExp.url)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style="color: cornflowerblue" fill="currentColor" class="bi bi-images" viewBox="0 0 16 16">
                              <path d="M4.502 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                              <path d="M14.002 13a2 2 0 0 1-2 2h-10a2 2 0 0 1-2-2V5A2 2 0 0 1 2 3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v8a2 2 0 0 1-1.998 2zM14 2H4a1 1 0 0 0-1 1h9.002a2 2 0 0 1 2 2v7A1 1 0 0 0 15 11V3a1 1 0 0 0-1-1zM2.002 4a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1h-10z" />
                            </svg>
                          </div>
                        </td>
                        <td v-if="validation == 0">
                          <div class="cell-inner justify-content-center pointer" v-if="allExp.userId == user.userId" @click="ModalEdit(allExp)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style="color: cornflowerblue" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                            </svg>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div v-else>Aucune dépense</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal  -->
      <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <img class="img-fluid img-thumbnail tourne mx-auto my-5 d-block" :src="url" alt="Justificatif d'une dépense" />
            </div>
            <div class="modal-footer"></div>
          </div>
        </div>
      </div>

      <!-- Modal  edit-->
      <div class="modal fade" id="myModalEdit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="display-1">Modification Dépense</h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="updateDepense()">
                <div class="form-row">
                  <div class="col-12 mb-3">
                    <label for="datedep" class="font-weight-medium mb-2">Date de la dépense :</label>
                    <v-date-picker v-model="date" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="col-12 mb-3">
                    <label class="font-weight-medium mb-2 required" for="carburant">Carburant (en L) :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="carburant" placeholder="" autocomplete="off" v-model="carburant" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <label class="font-weight-medium mb-2 required" for="peage">Péage (en €) :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="peage" placeholder="" autocomplete="off" v-model="peage" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <label class="font-weight-medium mb-2 required" for="lavage">Lavage (en €) :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="lavage" placeholder="" autocomplete="off" v-model="lavage" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <label class="font-weight-medium mb-2 required" for="autre">Autre (en €) :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="autre" placeholder="" autocomplete="off" v-model="autre" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                </div>

                <div class="form-error mt-3" v-if="status == 'error_depense'">
                  <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                  <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                    <li>{{ err.message }}</li>
                  </ul>
                </div>

                <div class="modal-footer">
                  <div class="row mt-3">
                    <div class="col-6 mb-3">
                      <button type="button" class="btn btn-block btn-secondary" @click="retour()">RETOUR</button>
                    </div>
                    <div class="col-6 mb-3">
                      <button class="btn btn-primary btn-block float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                        <span v-if="status == 'loading'">Connexion en cours</span>
                        <span v-else>VALIDER</span>
                      </button>
                    </div>
                    <div class="col-12 mb-3">
                      <button type="button" class="btn btn-block btn-danger" @click="deleteExp(exp)">SUPPRIMER</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Qr Code -->
      <div class="modal fade" id="myModalQrCode" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="display-1">Qr Code</h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <img class="img-fluid img-thumbnail mx-auto d-block" :src="url" alt="QrCode" id="qrcode" />
            </div>
            <div class="modal-footer"> <button type="button" class="btn btn-block btn-primary" @click="Print(url)">IMPRIMER</button> </div>
          </div>
        </div>
      </div>
      <!-- Modal Validation -->
      <div class="modal fade" id="myModalValidation" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="display-1">VALIDATION</h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body mt-3 mb-3">
              Vous êtes sur le point de valider le journal de service du véhicule immatriculé <span class="text-primary">{{ vehicule.registration }} </span> du mois de <span class="text-primary">{{ lettreMois }} - {{ annee }} </span>.
            </div>
            <div class="modal-footer">
              <div class="col-6">
                <button type="button" class="btn btn-block btn-secondary" data-dismiss="modal">RETOUR</button>
              </div>
              <div class="col-6">
                <button class="btn btn-primary btn-block float-right" type="submit" @click="validationJs()">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>VALIDER</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "Journal",
  data() {
    return {
      valueIdVehicule: null,
      valueMois: null,
      valueAnnee: null,
      valueUnite: null,
      uniteId: "",
      vehiculeId: "",
      url: "",
      date: "",
      carburant: "",
      peage: "",
      lavage: "",
      autre: "",
      exp: "",
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
    };
  },
  created() {
    if (this.$route.params.vehiculeId) {
      this.valueIdVehicule = this.$route.params.vehiculeId;
    }
    if (this.$route.params.retourMois) {
      this.valueMois = this.$route.params.retourMois;
    }
    if (this.$route.params.retourAnnee) {
      this.valueAnnee = this.$route.params.retourAnnee;
    }
    if (this.$route.params.unitId) {
      this.valueUnite = this.$route.params.unitId;
    }

    this.$store.commit("title", {
      desktop: "Journal de Service",
      mobile: "Journal de Service",
    });
    this.$store.dispatch("getProfil");
    this.$store.dispatch("getAllUnits");
    if (this.valueUnite) {
      this.$store.dispatch("getAllVehicules", { userId: this.user.userId, id_unit: this.valueUnite });
      this.$store.dispatch("getAllLogsVehicle", { month: this.valueMois, year: this.valueAnnee, id_vehicle: this.valueIdVehicule });
      this.$store.dispatch("getAllExpenses", { month: this.valueMois, year: this.valueAnnee, id_vehicle: this.valueIdVehicule });
      this.$store.dispatch("getValidation", { userId: this.user.userId, month: this.valueMois, year: this.valueAnnee, id_vehicle: this.valueIdVehicule });
    } else {
      const self = this;
      this.$store.dispatch("getLastLogsUser").then(function () {
        if (self.lastLogsUser) {
          self.valueUnite = self.lastLogsUser.lastLogUser.id_unit;
          self.valueIdVehicule = self.lastLogsUser.lastLogUser.id_vehicle;
          self.$store.commit("Vehicules", self.lastLogsUser.vehicule);
          self.$store.commit("allVehicules", self.lastLogsUser.allVehicules);
          self.$store.dispatch("getAllLogsVehicle", { month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: self.idVehicule });
          self.$store.dispatch("getAllExpenses", { month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: self.idVehicule });
          self.$store.dispatch("getValidation", { userId: self.user.userId, month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: self.idVehicule });
        } else {
          self.$store.dispatch("getAllVehicules", { userId: self.user.userId, id_unit: self.user.unit }).then(function () {
            self.valueIdVehicule = self.allVehicules[0].idVehicle;
            self.$store.dispatch("getOneVehicules", self.idVehicule);
            self.$store.dispatch("getAllLogsVehicle", { month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: self.idVehicule });
            self.$store.dispatch("getAllExpenses", { month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: self.idVehicule });
            self.$store.dispatch("getValidation", { userId: self.user.userId, month: new Date().getMonth() + 1, year: new Date().getFullYear(), id_vehicle: self.idVehicule });
          });
        }
      });
    }
  },

  computed: {
    ...mapState(["status", "errMessage", "validation", "allVehicules", "user", "vehicule", "profil", "allLogsVehicle", "lastLogsUser", "allDepenses", "host", "allUnits"]),
    validatedFields() {
      return (this.carburant != "" || this.peage != "" || this.lavage != "" || this.autre != "") && this.date != "" ? true : false;
    },
    idVehicule: {
      get() {
        return this.valueIdVehicule === null ? this.vehiculeId : this.valueIdVehicule;
      },
      set(value) {
        this.valueIdVehicule = value;
      },
    },
    mois: {
      get() {
        return this.valueMois === null ? new Date().getMonth() + 1 : this.valueMois;
      },
      set(value) {
        this.valueMois = value;
      },
    },
    annee: {
      get() {
        return this.valueAnnee === null ? new Date().getFullYear() : this.valueAnnee;
      },
      set(value) {
        this.valueAnnee = value;
      },
    },
    test() {
      return 8;
    },
    unite: {
      get() {
        return this.valueUnite === null ? this.user.unit : this.valueUnite;
        //return this.valueUnite === null ? this.vehicule.id_unit : this.valueUnite;
      },
      set(value) {
        this.valueUnite = value;
      },
    },
    lettreMois() {
      if (this.mois == "1") {
        return "Janvier";
      }
      if (this.mois == "2") {
        return "Février";
      }
      if (this.mois == "3") {
        return "Mars";
      }
      if (this.mois == "4") {
        return "Avril";
      }
      if (this.mois == "5") {
        return "Mai";
      }
      if (this.mois == "6") {
        return "Juin";
      }
      if (this.mois == "7") {
        return "Juillet";
      }
      if (this.mois == "8") {
        return "Aout";
      }
      if (this.mois == "9") {
        return "Septembre";
      }
      if (this.mois == "10") {
        return "Octobre";
      }
      if (this.mois == "11") {
        return "Novembre";
      }
      if (this.mois == "12") {
        return "Décembre";
      }
    },
  },
  methods: {
    Modal(url) {
      this.url = this.host + "images/factures-JS/" + url;
      jQuery("#myModal").modal({
        keyboard: true,
        toggle: true,
      });
    },
    ModalEdit(exp) {
      this.exp = exp;
      this.date = exp.date;
      this.carburant = exp.carburant.toString();
      this.lavage = exp.lavage.toString();
      this.peage = exp.peage.toString();
      this.autre = exp.autre.toString();
      jQuery("#myModalEdit").modal({
        keyboard: true,
        toggle: true,
      });
    },
    ModalQrCode(id) {
      const data = this.host + "js/NewJs/" + id;
      this.url = "https://chart.googleapis.com/chart?cht=qr&chl=" + data + "&chs=200x200&choe=UTF-8&chld=L|0";
      jQuery("#myModalQrCode").modal({
        keyboard: true,
        toggle: true,
      });
    },
    async Print(qrcode) {
      const self = this;
      const data = {
        url: qrcode,
        immat: this.vehicule.registration,
      };
      var windowReference = window.open();
      await this.$store.dispatch("exportQrCode", data);
      const lienPdf = self.host + "images/export-Qrcode/" + data.immat + ".pdf";
      console.log(lienPdf);
      windowReference.location = lienPdf;
    },
    ModalValidation() {
      jQuery("#myModalValidation").modal({
        keyboard: true,
        toggle: true,
      });
    },
    changeSelect() {
      this.$store.dispatch("getAllLogsVehicle", { month: this.mois, year: this.annee, id_vehicle: this.idVehicule });
      this.$store.dispatch("getOneVehicules", this.idVehicule);
      this.$store.dispatch("getAllExpenses", { month: this.mois, year: this.annee, id_vehicle: this.idVehicule });
      this.$store.dispatch("getValidation", { userId: this.user.userId, month: this.mois, year: this.annee, id_vehicle: this.idVehicule });
    },
    changeUnite() {
      const self = this;
      this.$store.dispatch("getAllVehicules", { userId: this.user.userId, id_unit: this.unite }).then(function () {
        self.valueIdVehicule = self.allVehicules[0].idVehicle;
        self.changeSelect();
      });
    },
    newJs() {
      if (this.valueIdVehicule === null) {
        this.valueIdVehicule = this.vehicule.idVehicle;
      }
      this.$router.push({ name: "NewJs", params: { id: this.valueIdVehicule, newJsMois: this.mois, newJsAnnee: this.annee } });
    },
    update(logs) {
      this.$router.push({ name: "Update", params: { logs: JSON.stringify(logs), mois: this.mois, annee: this.annee } });
    },
    depense() {
      if (this.valueIdVehicule === null) {
        this.valueIdVehicule = this.vehicule.idVehicle;
      }
      this.$router.push({ name: "Depense", params: { id: this.valueIdVehicule, mois: this.mois, annee: this.annee } });
    },
    retour() {
      jQuery("#myModalEdit").modal("hide");
      this.$store.commit("setStatus", "");
      this.$store.commit("errMessage", "");
    },
    updateDepense() {
      const self = this;
      if (this.carburant == "") {
        this.carburant = 0;
      } else {
        this.carburant = this.carburant.split(",").join(".");
      }
      if (this.peage == "") {
        this.peage = 0;
      } else {
        this.peage = this.peage.split(",").join(".");
      }
      if (this.lavage == "") {
        this.lavage = 0;
      } else {
        this.lavage = this.lavage.split(",").join(".");
      }
      if (this.autre == "") {
        this.autre = 0;
      } else {
        this.autre = this.autre.split(",").join(".");
      }
      this.$store
        .dispatch("updateDepense", {
          carburant: this.carburant,
          peage: this.peage,
          lavage: this.lavage,
          autre: this.autre,
          date: this.date.split("/").reverse().join("-"),
          userId: this.user.userId,
          idExpense: this.exp.idExpense,
        })
        .then(function () {
          jQuery("#myModalEdit").modal("hide");
          self.showAlert({
            icon: "success",
            title: "Vous avez modifié votre dépense avec succès.",
          });
          self.$store.dispatch("getAllExpenses", { month: self.mois, year: self.annee, id_vehicle: self.idVehicule });
        });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
      });
    },
    deleteExp(exp) {
      const self = this;

      this.$store.dispatch("deleteDepense", exp).then(function () {
        jQuery("#myModalEdit").modal("hide");
        self.showAlert({
          icon: "success",
          title: "Vous avez supprimée la dépense avec succès.",
        });
        self.$store.dispatch("getAllExpenses", { month: self.mois, year: self.annee, id_vehicle: self.idVehicule });
      });
    },
    validationJs() {
      const self = this;
      this.$store.dispatch("validationJs", { userId: this.user.userId, month: this.mois, year: this.annee, id_vehicle: this.idVehicule }).then(function () {
        self.envoiMail();
        self.$store.dispatch("getValidation", { userId: self.user.userId, month: self.mois, year: self.annee, id_vehicle: self.idVehicule });
      });
    },
    envoiMail() {
      const self = this;
      const data = {
        allLogs: this.allLogsVehicle,
        allDepenses: this.allDepenses,
        vehicule: this.vehicule,
        mois: this.mois,
        lettreMois: this.lettreMois,
        annee: this.annee,
      };
      let moisPdf = "";
      if (data.mois == 1) {
        moisPdf = "01";
      }
      if (data.mois == 2) {
        moisPdf = "02";
      }
      if (data.mois == 3) {
        moisPdf = "03";
      }
      if (data.mois == 4) {
        moisPdf = "04";
      }
      if (data.mois == 5) {
        moisPdf = "05";
      }
      if (data.mois == 6) {
        moisPdf = "06";
      }
      if (data.mois == 7) {
        moisPdf = "07";
      }
      if (data.mois == 8) {
        moisPdf = "08";
      }
      if (data.mois == 9) {
        moisPdf = "09";
      }
      if (data.mois == 10) {
        moisPdf = "10";
      }
      if (data.mois == 11) {
        moisPdf = "11";
      }
      if (data.mois == 12) {
        moisPdf = "12";
      }
      const exportUrl = self.host + "images/export-PDF/" + data.annee + "/" + moisPdf + "/" + data.vehicule.registration + ".pdf";
      this.$store.dispatch("exportPdf", data).then(function () {
        self.$store.dispatch("envoiMail", {
          from: "Journal De Service <autope@infpe.fr>",
          to: self.profil.email,
          subject: self.vehicule.registration + " - " + self.lettreMois + " " + self.annee,
          html: "<b>Journal de service validé. </b><br><br><b>Immatriculation : </b>" + self.vehicule.registration + "<br><b>Mois : </b>" + self.lettreMois + "<br><b>Année : </b>" + self.annee,
          attachments: [
            {
              filename: data.vehicule.registration + " - " + self.lettreMois + " " + data.annee + ".pdf",
              href: exportUrl,
              contentType: "application/pdf",
            },
          ],
        });
        jQuery("#myModalValidation").modal("hide");
        self.showAlert({
          icon: "success",
          title: "JS validé avec succès.",
        });
      });
    },
    async pdf() {
      const self = this;
      const data = {
        allLogs: this.allLogsVehicle,
        allDepenses: this.allDepenses,
        vehicule: this.vehicule,
        mois: this.mois,
        lettreMois: this.lettreMois,
        annee: this.annee,
      };
      let moisPdf = "";
      if (data.mois == 1) {
        moisPdf = "01";
      }
      if (data.mois == 2) {
        moisPdf = "02";
      }
      if (data.mois == 3) {
        moisPdf = "03";
      }
      if (data.mois == 4) {
        moisPdf = "04";
      }
      if (data.mois == 5) {
        moisPdf = "05";
      }
      if (data.mois == 6) {
        moisPdf = "06";
      }
      if (data.mois == 7) {
        moisPdf = "07";
      }
      if (data.mois == 8) {
        moisPdf = "08";
      }
      if (data.mois == 9) {
        moisPdf = "09";
      }
      if (data.mois == 10) {
        moisPdf = "10";
      }
      if (data.mois == 11) {
        moisPdf = "11";
      }
      if (data.mois == 12) {
        moisPdf = "12";
      }
      var windowReference = window.open();
      await this.$store.dispatch("exportPdf", data);
      const lienPdf = self.host + "images/export-PDF/" + data.annee + "/" + moisPdf + "/" + data.vehicule.registration + ".pdf";
      windowReference.location = lienPdf;

      self.showAlert({
        icon: "success",
        title: "Export Pdf réalisé avec succès.",
      });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
.tourne {
  transform: rotate(90deg);
}
</style>
