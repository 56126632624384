<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-white rounded-top text-primary text-uppercase">Ajouter une dépense :</h5>

            <div class="card-body">
              <form @submit.prevent="addDepense()">
                <div class="form-row">
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="carburant">Carburant (en L) :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="carburant" placeholder="" autocomplete="off" v-model="carburant" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="peage">Péage (en €) :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="peage" placeholder="" autocomplete="off" v-model="peage" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="lavage">Lavage (en €) :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="lavage" placeholder="" autocomplete="off" v-model="lavage" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="autre">Autre (en €) :</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="autre" placeholder="" autocomplete="off" v-model="autre" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-3 col-sm-6 mb-3">
                    <label for="datedep" class="font-weight-medium mb-2 required">Date de la dépense :</label>
                    <v-date-picker locale="fr" v-model="date" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="file-input">Justificatif :</label>
                    <div class="form-control-container">
                      <input type="file" name="file-input" id="file-input" class="form-control file-input__input" @change="PreviewFile()" />

                      <label class="file-input__label bg-primary text-white" for="file-input">
                        <i class="icons-download icons-size-20px" aria-hidden="true"></i>
                        <span v-if="contentImageUrl === null">Choissisez votre Photo</span>
                        <span v-else>{{ contentImageUrl.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="form-error mt-3" v-if="status == 'error_depense'">
                  <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                  <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                    <li>{{ err.message }}</li>
                  </ul>
                </div>
                <div class="row justify-content-end mt-3">
                  <div class="col-md-3 col-sm-6 mb-3">
                    <button type="button" class="btn btn-block btn-secondary" @click="retour()">RETOUR</button>
                  </div>
                  <div class="col-md-3 col-sm-6 mb-3">
                    <button class="btn btn-primary btn-block float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                      <span v-if="status == 'loading'">Connexion en cours</span>
                      <span v-else>VALIDER</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Depense",
  props: ["id", "mois", "annee"],
  data() {
    return {
      carburant: "",
      peage: "",
      lavage: "",
      autre: "",
      date: "",
      contentImageUrl: null,
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
    };
  },
  mounted() {
    this.$store.commit("title", {
      desktop: "Ajouts Dépenses",
      mobile: "dépenses",
    });
  },
  computed: {
    validatedFields() {
      return (this.carburant != "" || this.peage != "" || this.lavage != "" || this.autre != "") && this.date != "" && this.contentImageUrl != null ? true : false;
    },
    ...mapState(["status", "errMessage", "user", "vehicule"]),
  },
  methods: {
    PreviewFile() {
      this.contentImageUrl = event.target.files[0];
    },
    retour() {
      this.$router.push({ name: "Journal", params: { vehiculeId: this.id, unitId: this.vehicule.id_unit, retourMois: this.mois, retourAnnee: this.annee } });
    },
    addDepense() {
      const self = this;
      if (this.carburant == "") {
        this.carburant = 0;
      } else {
        this.carburant = this.carburant.split(",").join(".");
      }
      if (this.peage == "") {
        this.peage = 0;
      } else {
        this.peage = this.peage.split(",").join(".");
      }
      if (this.lavage == "") {
        this.lavage = 0;
      } else {
        this.lavage = this.lavage.split(",").join(".");
      }
      if (this.autre == "") {
        this.autre = 0;
      } else {
        this.autre = this.autre.split(",").join(".");
      }
      this.$store
        .dispatch("addDepense", {
          photo: this.contentImageUrl,
          carburant: this.carburant,
          peage: this.peage,
          lavage: this.lavage,
          autre: this.autre,
          date: this.date.split("/").reverse().join("-"),
          userId: this.user.userId,
          id_vehicle: this.id,
        })
        .then(function () {
          jQuery("#myModal").modal("hide");
          self.showAlert({
            icon: "success",
            title: "Vous avez ajouté une dépense avec succès.",
          });
          self.$router.push({ name: "Journal", params: { vehiculeId: self.id, unitId: self.vehicule.id_unit, retourMois: self.mois, retourAnnee: self.annee } });
        });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
      });
    },
  },
};
</script>

<style>
.file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  border-radius: 8px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  padding: 0.7rem;
}
</style>
