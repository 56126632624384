<template>
  <section>
    <div class="container-fluid pt-3">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">{{ profil.site }} - {{ profil.nameUnit }}</h5>
            <div class="card-body">
              <h5 class="card-title mb-3">Ajouter une carte essence :</h5>

              <form @submit.prevent="addCard()">
                <div class="form-row">
                  <div class="col-md-6 mb-3" v-if="user.role == 1">
                    <label for="secteur" class="font-weight-medium mb-2 required">Choix du secteur</label>
                    <select id="secteur" v-model="unite" class="form-control">
                      <option value="" disabled hidden>Choisissez votre unité</option>
                      <option v-for="(unit, index) in allUnits" :key="index" :value="unit.idUnit" :data="unit.idUnit">{{ unit.nameUnit }}</option>
                    </select>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="num">Numéro de carte</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="num" placeholder="Numéro de carte" autocomplete="off" v-model="num" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-6 mb-3">
                    <label class="font-weight-medium mb-2 required" for="nom">Nom de la carte</label>
                    <div class="form-control-container">
                      <input type="text" class="form-control" id="nom" placeholder="Nom de la carte" autocomplete="off" v-model="name" aria-describedby="invalidnom" />
                      <span class="form-control-state"></span>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 mb-3">
                    <label for="dateMad" class="font-weight-medium mb-2">Date de mise à disposition</label>
                    <v-date-picker v-model="dateMad" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="col-md-6 col-sm-6 mb-3">
                    <label for="dateExp" class="font-weight-medium mb-2">Date d'expiration</label>
                    <v-date-picker v-model="dateExp" :model-config="modelConfig">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input class="form-control" autocomplete="off" placeholder="JJ/MM/AAAA" :value="inputValue" v-on="inputEvents" />
                      </template>
                    </v-date-picker>
                  </div>
                </div>

                <div class="form-error mt-3" v-if="status == 'error_card'">
                  <h2 class="text-white text-uppercase">Oouups ! Vous avez une erreur</h2>
                  <ul class="mt-1 mb-0" v-for="(err, index) in errMessage" :key="index">
                    <li>{{ err.message }}</li>
                  </ul>
                </div>

                <button class="btn btn-primary mt-3 float-right" type="submit" :disabled="!validatedFields" :class="{ disabled: !validatedFields }">
                  <span v-if="status == 'loading'">Connexion en cours</span>
                  <span v-else>VALIDER</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="card">
            <h5 class="card-header bg-primary rounded-top text-white text-uppercase">Liste des cartes essences</h5>

            <div class="card-body">
              <TableCards />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import TableCards from "@/components/Tables/Table_Cartes.vue";
import $ from "jquery";

export default {
  name: "Cards",
  components: { TableCards },
  data() {
    return {
      num: "",
      name: "",
      dateMad: "",
      dateExp: "",
      unite: "",
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY", // Uses 'iso' if missing
      },
    };
  },
  mounted() {
    this.$store.commit("title", {
      desktop: "Gestion des cartes essence",
      mobile: "Cartes essence",
    });
    this.$store.dispatch("getProfil");
    this.$store.dispatch("getAllUnits");
  },

  methods: {
    addCard() {
      if (this.unite == "") {
        this.unite = parseInt(this.user.unit);
      } else {
        this.unite = parseInt(this.unite);
      }
      const self = this;
      this.$store
        .dispatch("addCards", {
          cardNumber: this.num,
          cardName: this.name.toUpperCase(),
          startingDate: this.dateMad.split("/").reverse().join("-"),
          expirationDate: this.dateExp.split("/").reverse().join("-"),
          id_unit: this.unite,
          id_site: this.user.site,
          userId: this.user.userId,
        })
        .then(function () {
          $("#tableCards").DataTable().destroy();
          self.showAlert({
            icon: "success",
            title: "La carte " + self.name + " a été ajoutée avec succès.",
          });
          self.num = "";
          self.name = "";
          self.dateMad = "";
          self.dateExp = "";
          self.unite = "";
        });
    },
    showAlert(param) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
        icon: param.icon,
        title: param.title,
        // footer: param.footer,
      });
    },
  },
  computed: {
    validatedFields() {
      if (this.user.role == 1) {
        return this.num != "" && this.name != "" && this.dateMad != "" && this.dateExp != "" && this.unite != "" ? true : false;
      } else {
        return this.num != "" && this.name != "" && this.dateMad != "" && this.dateExp != "" ? true : false;
      }
    },
    ...mapState(["status", "errMessage", "profil", "user", "allUnits"]),
  },
};
</script>

<style></style>
