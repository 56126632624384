<template>
  <section>
    <button type="button" class="btn btn-info mt-5" @click="envoiMail()">Envoyer un Email</button>
  </section>
</template>
<script>
import $ from "jquery";
export default {
  components: {},
  data() {
    return {};
  },
  beforeMount() {
    this.$store.commit("title", {
      desktop: "Accès Paris Est",
      mobile: "Accès",
    });
  },
  computed: {},
  methods: {
    envoiMail() {
      this.$store.dispatch("envoiMail", {
        from: "Journal De Service <journaldeservice@journaldeservice.applissimo.fr>",
        to: "dutdut77@hotmail.fr",
        subject: "AA-345-AA",
        html: "<b>Le Js à été validé </b>",
      });
    },
  },
};
</script>
<style></style>
